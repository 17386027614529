import React from 'react';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
    return (
        < >
            <Helmet>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>11 + Practice Papers | Privacy Policy | Practice Papers</title>
                <meta
                    name="description"
                    content="Read the Privacy Policy for using Practice Papers, your trusted platform for UK 11+ exam preparation."
                />
                <meta
                    name="keywords"
                    content="11 plus exams, grammar schools, UK education, CEM, GL assessment, practice tests, terms and conditions, privacy policy"
                />
                <meta name="author" content="Practice Papers" />
                <style>{`
          /* ==== Global Reset & Base Styles ==== */
          * { margin: 0; padding: 0; box-sizing: border-box; }
          a {
            color: #007BFF;
            text-decoration: none;
            font-weight: bold;
          }
          a:hover {
            color: #0056b3;
            text-decoration: underline;
          }
          
          
          
          /* ==== Privacy & Conditions ==== */
          .privacy-container {
            max-width: 900px;
            margin: 80px auto 40px;
            padding: 30px;
            background: white;
            box-shadow: 0 4px 10px rgba(0,0,0,0.1);
            border-radius: 8px;
          }
          h1 { color: #007BFF; text-align: center; }
          .updated-date { text-align: center; color: gray; font-size: 0.9em; margin-bottom: 20px; }
          h2 { margin-top: 20px; color: #0056b3; font-size: 18px }
          ul { margin: 10px 0; padding-left: 20px; }
          ul li { margin-bottom: 5px; }
          
          
          
          /* ==== Responsive Styles ==== */
          @media (max-width: 768px) {
            .features-container { flex-direction: column; align-items: center; }
            .feature { width: 80%; }
            .carousel-slide .slide-content h2 { font-size: 2.2em; }
            .carousel-slide .slide-content p { font-size: 1em; }
          }
        `}</style>
            </Helmet>

            <main class="privacy-container">
                <h1>11 + Practice Papers - Privacy Policy</h1>
                <p class="updated-date">Last Updated: January 2025</p>

                <section>
                    <h2>1. Introduction</h2>
                    <p>Welcome to <a href="https://practicepapers.net/">Practice Papers</a>! Practice Papers ("we", "us", or "our") is committed to safeguarding the privacy of our users ("you" or "your"). This Privacy Policy outlines how we collect, use, disclose, and protect your personal data when you access our website (the "Website") and utilise our services (the "Services").</p>
                        <p>We adhere to the UK General Data Protection Regulation (UK GDPR) and other relevant data protection laws.</p>
                </section>

                <section>
                    <h2>2. Information We Collect</h2>
                    <p>We collect personal data to provide and improve our Services. The types of information we collect include:</p>
                    <ul>
                        <li>Sign up (Registration) Information: Your name, email address, and postcode when you sign up for an account.</li>
                        <li>Child Account Information: If you create a child account, we collect the child's date of birth to provide age-appropriate practice materials.</li>
                        <li>Cookies and Tracking Data: We use cookies (see Section 5 - Cookies) to enhance user experience.</li>
                    </ul>
                    <p>We do not collect special categories of personal data (e.g., race, ethnicity, political opinions, religious beliefs) or information about criminal convictions.</p>
                </section>

                <section>
                    <h2>3. Legal Basis for Processing</h2>
                    <p>Our processing of your personal data is based on the following legal grounds:</p>
                    <ul>
                        <li>Performance of a Contract: To provide you with our Services.</li>
                        <li>Legal Obligation: To comply with UK laws and regulations.</li>
                        <li>Legitimate Interests: To enhance user experience and improve our services provided it does not override your rights.</li>
                    </ul>
                </section>
                <section>
                    <h2>4. How We Use Your Information</h2>
                    <p>We use your personal data for the following purposes:</p>
                    <ul>
                        <li>Provide and Personalise Services: To create and manage your account, provide practice materials, and personalise your experience by using analytics (see Section 5).</li>
                        <li>Communicate with You: To send you service-related messages, updates, and marketing communications.</li>
                        <li>Improve Services: To analyse user behaviour, track usage, and improve our Services.</li>
                        <li>Comply with Legal Obligations: To comply with legal requirements and respond to law enforcement requests.</li>
                    </ul>
                </section>
                <section>
                    <h2>5. Cookies and Tracking Technologies</h2>
                    <p>We use cookies to ensure the best possible experience on our Website. A cookie is a small text file stored on your device to help us recognize and improve your interactions with our Website.</p>
                    <p>We use the following types of cookies:</p>
                    <ul>
                        <li>Essential Cookies: Required for the Website to function properly.</li>
                        <ul>
                            <li>Used for sign-up and sign-in purposes</li>
                            <li>Required to deliver practice tests (without them, tests cannot be provided)</li>
                        </ul>
                        <li>Analytics Cookies: Used to track user behaviour and improve our Services.</li>
                        <li>Marketing Cookies: Used to display relevant ads and marketing messages.</li>
                    </ul>
                    <p>By using the Website, you consent to the use of cookies in accordance with this Privacy Policy. You may disable cookies in your browser settings; however, this may affect the functionality of certain features, including access to practice tests.</p>

                </section>
                <section>
                    <h2>6. Data Sharing and Disclosure</h2>
                    <p>We do not sell or rent your personal data. We may share your data in the following cases:</p>
                    <ul>
                        <li>Service Providers: To provide services on our behalf (e.g., hosting, analytics).</li>
                        <li>Legal Compliance: To comply with legal obligations and respond to law enforcement requests.</li>
                        <li>Business Transfers: In connection with a merger, acquisition, or sale of assets.</li>
                        <li>Consent: With your consent or at your direction.</li>
                    </ul>
                    <p>We do not sell or rent your personal data to third parties for marketing purposes.</p>
                </section>
                <section>
                    <h2>7. Data Retention</h2>
                    <p>We retain your data only as long as necessary to fulfil the purposes outlined in this Privacy Policy</p>
                    <ul>
                        <li>Your data is retained while your account is active.</li>
                        <li>If your account is inactive for an extended period, we may archive or delete your data.</li>
                        <li>You can request account deletion by <a href="https://practicepapers.net/contact-us"> contacting us.</a></li>
                    </ul>
                </section>
                <section>
                    <h2>8. Your Rights under UK GDPR</h2>
                    <p>You have the following rights regarding your personal data:</p>
                    <ul>
                        <li>Access: You can request a copy of your personal data.</li>
                        <li>Rectification: You can update or correct your personal data.</li>
                        <li>Erasure: You can request deletion of your personal data in certain circumstances.</li>
                        <li>Restriction: You can request restriction of processing your personal data.</li>
                        <li>Portability: You can request a copy of your data in a structured, machine-readable format.</li>
                        <li>Objection: You can object to processing your personal data based on legitimate interests.</li>
                    </ul>
                    <p>To exercise these rights, please <a href="https://practicepapers.net/contact-us"> contact us.</a></p>
                </section>
                <section>
                    <h2>9. Data Security</h2>
                    <p>We implement appropriate security measures to protect your data from unauthorised access, loss, or misuse. However, no system is 100% secure. We encourage you to keep your login details confidential.</p>
                </section>
                <section>
                    <h2>10. Children's Privacy</h2>
                    <p>Our Services are not intended for children under 13 and their parents / guardians to manage their child's learning journey. We do not knowingly collect personal data from children under 13. If you believe a child has provided personal data without parental consent, please <a href="https://practicepapers.net/contact-us"> contact us.</a></p>
                </section>
                <section>
                    <h2>11. Changes to this Privacy Policy</h2>
                    <p>We may update this Privacy Policy from time to time.
                        <ul>
                            <li>If material changes occur, we will notify you via email or an on-site notice.</li>
                            <li>We encourage users to review this page regularly.</li>
                            <li>Continued use of our Services after updates constitutes acceptance of the revised Privacy Policy.</li>
                        </ul>
                    </p>
                </section>
                <section>
                    <h2>12. Contact Us</h2>
                    <p>If you have any questions or concerns about this Privacy Policy, please <a href="https://practicepapers.net/contact-us"> contact us.</a></p>
                </section>


            </main>
        </>
    );

};


export default PrivacyPolicy;;
