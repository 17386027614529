import React from "react";
import { Helmet } from 'react-helmet';

const Footer = () => {
    return (
      <footer className="footer mt-5 mb-0">
        <div class="footer-links "> 
          <a href="/about-exams">About 11+ Exams</a>
          <a href="#">Grammar Schools in UK</a>
          <a href="#">Exam Preparation Tips</a>
          <a href="#">Contact Us</a>
        </div>
        <p>&copy; 2025 Practice Papers. All rights reserved.</p>
      </footer>

    );
}

export default Footer;


