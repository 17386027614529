import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import studentStudyingImage from '../../images/student_studying.jpg';
import studentsStudyingImage from '../../images/students_studying.jpg';
import studentsJoy from '../../images/students_joy.jpg';
import Footer from '../Static/Footer';
import axios from 'axios';
import { useLoading } from '../Static/LoadingContext';

const LandingPage = (props) => {
  // Slide data for the unified background carousel
  const [activeSlide, setActiveSlide] = useState(0);
  // State for toggling the mobile sign in modal
  const [showSignIn, setShowSignIn] = useState(false);
 
  const initialValues = { username: "", password: "" };
  const [signinValues, setSigninValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("success");
  const navigate = useNavigate();
  const { setIsLoading } = useLoading();
  const location = useLocation();
  const message = location?.state?.message;
  const success = props.success;
  const verificationmessage = props.verificationmessage;

  // Toast message effect
  useEffect(() => {
    if (message) {
      setToastMessage(message);
      setToastVariant("success");
      setShowToast(true);
    }
    if (success === true) {
      setToastMessage(verificationmessage);
      setToastVariant("success");
      setShowToast(true);
    }
  }, [message, success, verificationmessage]);

  // Authentication token storage
  const storeAuthToken = (token, userType, children, firstName) => {
    return new Promise((resolve, reject) => {
      if (token && userType) {
        axios.defaults.headers.common['Authorization'] = `Token ${token}`;
        localStorage.setItem('authToken', token);
        localStorage.setItem('userType', userType);
        localStorage.setItem('firstName', firstName);
        if (userType === "parent") {
          localStorage.setItem('children', JSON.stringify(children));
        }
        resolve();
      } else {
        delete axios.defaults.headers.common['Authorization'];
        localStorage.removeItem('authToken');
      }
    });
  };

  // Form handlers
  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue = name === "username" ? value.toLowerCase() : value;
    setSigninValues((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));

    setFormErrors((prevState) => {
      const errors = { ...prevState };
      delete errors[name];
      return errors;
    });
  };

  const handleSubmit = (event) => {
    const errors = validate(signinValues);
    setFormErrors(errors);
    setIsSubmit(true);
  };

  // Submit effect for API call
  useEffect(() => {
    if (isSubmit && Object.keys(formErrors).length === 0) {
      setIsLoading(true);
      axios.post(process.env.REACT_APP_BACKEND_API_URL + "/login/", signinValues)
        .then((response) => {
          const authToken = response.data.token;
          const userType = response.data.userType;
          const children = response.data.children;
          const firstName = response.data.firstName;
          storeAuthToken(authToken, userType, children, firstName).then(() => {
            if (userType === "child") {
              navigate("/ppstartpage");
            } else if (userType === "parent") {
              navigate("/home", { state: { userType, children } });
            } else if (userType === "qbAdmin" || userType === "super admin") {
              navigate("/addquestions");
            } else if (userType === "qbDeveloper") {
              navigate("/mathsquestions");
            }
          });
        })
        .catch((error) => {
          if (error.response == undefined) {
            setErrorMessage("We are currently facing technical issue. Please try again later. (Error code " + error.code + ")");
          }
          else if (error.response.status === 401) {
            setErrorMessage(error.response.data.detail);
          } else if (error.response.status === 500) {
            setErrorMessage("Something went Wrong. Please try again.");
          }
          else{
            setErrorMessage(error.response.data);
          }
        })
        .finally(() => {
          setIsSubmit(false);
          setIsLoading(false);
        });
    } else {
      setIsSubmit(false);
    }
  }, [formErrors, isSubmit, signinValues]);

  // Check existing auth token
  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    const userType = localStorage.getItem('userType');
    const children = JSON.parse(localStorage.getItem("children"));
    const firstName = localStorage.getItem("firstName");
    if (authToken && userType) {
      storeAuthToken(authToken, userType, children, firstName).then(() => {
        if (userType === "child") {
          navigate("/ppstartpage");
        } else if (userType === "parent") {
          navigate("/home", { state: { userType, children } });
        } else if (userType === "qbAdmin" || userType === "super admin") {
          navigate("/addquestions");
        }
        else if (userType === "qbDeveloper") {
          navigate("/mathsquestions");
        }
      });
    }
  }, []);

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = "Username is required!";
    }
    if (values.username === "") {
      errors.username = "Username cannot be empty";
    }
    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password.length < 4) {
      errors.password = "Password must be more than 8 characters or above";
    }
    return errors;
  };

  const slides = [
    {
      bgImage: "https://source.unsplash.com/1600x900/?education,exam",
      title: "Unlock Your Child’s UK 11+ Exam Potential",
      text: "Experience free, comprehensive practice tests designed for both CEM and GL exams. Boost your child's confidence with realistic exam simulations and detailed step-by-step explanations.",
      buttonText: "Get Started for Free"
    },
    {
      bgImage: "https://source.unsplash.com/1600x900/?classroom,learning",
      title: "Get Started in 3 Easy Steps",
      text: "Simply register, verify your account, and create your child’s profile. Our intuitive platform makes UK 11+ exam preparation simple and effective.",
      buttonText: "Get Started for Free"
    },
    {
      bgImage: "https://source.unsplash.com/1600x900/?progress,study",
      title: "Actionable Insights",
      text: "Monitor your child’s progress with detailed analytics and personalised performance reports. Set realistic goals and track improvements to ensure exam success.",
      buttonText: "Get Started for Free"
    }
  ];

  useEffect(() => { const interval = setInterval(() => setActiveSlide(prev => (prev + 1) % slides.length), 3000); return () => clearInterval(interval); }, []);


  return (
    <div className="min-h-screen flex flex-col pb-16 lg:pb-0">
      {/* Unified Carousel/Sign In Section */}
      <div className="flex-1">
        {/* Desktop View */}
        <div className="hidden lg:flex relative h-[60vh] overflow-hidden">
          {/* Unified Background */}
          <div
            className="absolute inset-0 bg-cover bg-center"
            style={{ backgroundImage: `url(${slides[activeSlide].bgImage})` }}
          ></div>
          {/* Dark Blue Gradient Overlay */}
          <div className="absolute inset-0 bg-gradient-to-b from-[#001f3f] to-[#00172d] opacity-100 "></div>
          {/* Decorative Elements – exactly as in the reference code */}
          {/* Circle (Top Left) */}
          <div
            className="absolute z-0"
            style={{
              top: "-40px",
              left: "-40px",
              width: "120px",
              height: "120px",
              background: "radial-gradient(circle, rgba(0,123,255,0.5), rgba(0,123,255,0) 70%)",
              borderRadius: "50%"
            }}
          ></div>
          {/* Rectangle (Bottom Right) */}
          <div
            className="absolute z-0"
            style={{
              bottom: "-30px",
              right: "-30px",
              width: "180px",
              height: "90px",
              background: "linear-gradient(45deg, rgba(255,215,0,0.7), rgba(255,215,0,0.3))",
              transform: "rotate(15deg)"
            }}
          ></div>
          {/* Square (Top Right) */}
          <div
            className="absolute z-0"
            style={{
              top: "10%",
              right: "-40px",
              width: "80px",
              height: "80px",
              background: "linear-gradient(45deg, rgba(0,123,255,0.6), rgba(0,123,255,0.2))"
            }}
          ></div>
          {/* Triangle (Bottom Left) */}
          <div
            className="absolute z-0"
            style={{
              bottom: "10%",
              left: "-30px",
              width: 0,
              height: 0,
              borderLeft: "40px solid transparent",
              borderRight: "40px solid transparent",
              borderBottom: "70px solid rgba(255,215,0,0.7)"
            }}
          ></div>
          {/* Decorative SVG: Pencil */}
          <svg
            className="absolute z-0 opacity-90"
            style={{ top: "20%", left: "10%", width: "48px", height: "48px" }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#FFD700"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M12 20h9" />
            <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4 12.5-12.5z" />
          </svg>
          {/* Decorative SVG: Scale */}
          <svg
            className="absolute z-0 opacity-90"
            style={{ bottom: "20%", right: "15%", width: "48px", height: "48px" }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#FFD700"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M4 3h16" />
            <path d="M12 3v18" />
            <path d="M4 21h16" />
            <path d="M7 3l5 6 5-6" />
          </svg>
          {/* Decorative SVG: Graduation Cap */}
          <svg
            className="absolute z-0 opacity-90"
            style={{ top: "10%", right: "10%", width: "48px", height: "48px" }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#FFD700"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M22 12l-10 5L2 12" />
            <path d="M12 22V12" />
            <path d="M2 12l10-5 10 5" />
          </svg>
          {/* Decorative SVG: Triangle */}
          <svg
            className="absolute z-0"
            style={{ top: "5%", left: "5%", width: "50px", height: "50px" }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
          >
            <polygon points="50,10 90,90 10,90" fill="#FFD700" opacity="0.5" stroke="#FFD700" strokeWidth="2" />
          </svg>
          {/* Decorative SVG: Circle */}
          <svg
            className="absolute z-0"
            style={{ top: "5%", right: "5%", width: "50px", height: "50px" }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
          >
            <circle cx="50" cy="50" r="40" fill="#007BFF" opacity="0.5" stroke="#007BFF" strokeWidth="2" />
          </svg>
          {/* Decorative SVG: Pentagon */}
          <svg
            className="absolute z-0"
            style={{ bottom: "5%", left: "5%", width: "50px", height: "50px" }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
          >
            <polygon points="50,10 90,38 73,85 27,85 10,38" fill="#FFD700" opacity="0.5" stroke="#FFD700" strokeWidth="2" />
          </svg>

          {/* Foreground Content – Two Columns */}
          <div className="relative z-10 flex w-full h-full">
            {/* Left Column: Carousel Text */}
            <div className="w-3/5 flex flex-col justify-center pt-20">
              <div className="text-center text-white px-4">
                <h2 className="text-3xl md:text-5xl font-bold mb-4 drop-shadow-lg">
                  {slides[activeSlide].title}
                </h2>
                <p className="text-lg md:text-xl mb-6 drop-shadow-md">
                  {slides[activeSlide].text}
                </p>
                <button
                  onClick={() => navigate("/parentguardiansignup")} 
                  className="bg-[#e67e22] hover:bg-[#FFC107] text-white font-bold py-3 px-6 rounded shadow-md transition-all duration-300"
                >
                  {slides[activeSlide].buttonText}
                </button>
                {/* Indicator Dots */}
                <div className="flex justify-center gap-2 mt-4">
                  {slides.map((_, index) => (
                    <div
                      key={index}
                      onClick={() => setActiveSlide(index)}
                      className={`cursor-pointer rounded-full transition-colors duration-300 w-3 h-3 ${activeSlide === index ? 'bg-blue-600' : 'bg-blue-300'}`}
                    ></div>
                  ))}
                </div>
              </div>
            </div>
            {/* Right Column: Sign In Form */}
            <div className="w-2/5 flex items-center justify-center relative">
              <div className="w-full max-w-md p-8 my-10 bg-[#F0F8FF] bg-opacity-100 rounded shadow-md">
                <div className="text-center mb-8">
                  <h2 className="text-2xl font-bold text-gray-900">Welcome</h2>
                  <p className="text-gray-600 mt-2">Sign in to your account</p>
                </div>

                {errorMessage && (
                  <div className="bg-red-50 border border-red-200 text-red-700 p-4 rounded mb-6">
                    {errorMessage}
                  </div>
                )}

                <form className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Username</label>
                    <input
                      type="text"
                      name="username"
                      value={signinValues.username}
                      onChange={handleChange}
                      onKeyDown={handleEnterKeyPress}
                      className={`mt-1 block w-full rounded px-3 py-2 shadow-sm focus:border-blue-500 focus:ring-blue-500 transition-all duration-300 ${
                        formErrors.username ? 'border-red-500' : 'border-gray-300'
                      }`}
                    />
                    {formErrors.username && (
                      <p className="mt-1 text-sm text-red-600">{formErrors.username}</p>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Password</label>
                    <input
                      type="password"
                      name="password"
                      value={signinValues.password}
                      onChange={handleChange}
                      onKeyDown={handleEnterKeyPress}
                      className={`mt-1 block w-full rounded px-3 py-2 shadow-sm focus:border-blue-500 focus:ring-blue-500 transition-all duration-300 ${
                        formErrors.password ? 'border-red-500' : 'border-gray-300'
                      }`}
                    />
                    {formErrors.password && (
                      <p className="mt-1 text-sm text-red-600">{formErrors.password}</p>
                    )}
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="w-full bg-blue-600 text-white rounded px-4 py-2 hover:bg-blue-700 shadow-md transition-all duration-300"
                    >
                      Sign In
                    </button>
                  </div>
                  <div className="text-center">
                    <Link to="/forgotpassword" className="text-sm text-blue-600 hover:text-blue-800">
                      Forgot your password?
                    </Link>
                  </div>
                  <div className="text-center">
                    <span className="text-sm text-gray-600">
                      No account yet?{" "}
                      <Link to="/parentguardiansignup" className="text-blue-600 hover:text-blue-800">
                        Sign up now
                      </Link>
                    </span>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>

        {/* Mobile View */}
        <div className="lg:hidden relative">
          {/* Unified background for mobile */}
          <div
            className="relative h-[60vh] overflow-hidden"
            style={{ backgroundImage: `url(${slides[activeSlide].bgImage})` }}
          >
            {/* Dark Blue Gradient Overlay */}
          <div className="absolute inset-0 bg-gradient-to-b from-[#001f3f] to-[#00172d] opacity-100 "></div>
          {/* Decorative Elements – exactly as in the reference code */}
          {/* Circle (Top Left) */}
          <div
            className="absolute z-0"
            style={{
              top: "-40px",
              left: "-40px",
              width: "120px",
              height: "120px",
              background: "radial-gradient(circle, rgba(0,123,255,0.5), rgba(0,123,255,0) 70%)",
              borderRadius: "50%"
            }}
          ></div>
          {/* Rectangle (Bottom Right) */}
          <div
            className="absolute z-0"
            style={{
              bottom: "-30px",
              right: "-30px",
              width: "180px",
              height: "90px",
              background: "linear-gradient(45deg, rgba(255,215,0,0.7), rgba(255,215,0,0.3))",
              transform: "rotate(15deg)"
            }}
          ></div>
          {/* Square (Top Right) */}
          <div
            className="absolute z-0"
            style={{
              top: "10%",
              right: "-40px",
              width: "80px",
              height: "80px",
              background: "linear-gradient(45deg, rgba(0,123,255,0.6), rgba(0,123,255,0.2))"
            }}
          ></div>
          {/* Triangle (Bottom Left) */}
          <div
            className="absolute z-0"
            style={{
              bottom: "10%",
              left: "-30px",
              width: 0,
              height: 0,
              borderLeft: "40px solid transparent",
              borderRight: "40px solid transparent",
              borderBottom: "70px solid rgba(255,215,0,0.7)"
            }}
          ></div>
          {/* Decorative SVG: Pencil */}
          <svg
            className="absolute z-0 opacity-90"
            style={{ top: "20%", left: "10%", width: "48px", height: "48px" }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#FFD700"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M12 20h9" />
            <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4 12.5-12.5z" />
          </svg>
          {/* Decorative SVG: Scale */}
          <svg
            className="absolute z-0 opacity-90"
            style={{ bottom: "20%", right: "15%", width: "48px", height: "48px" }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#FFD700"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M4 3h16" />
            <path d="M12 3v18" />
            <path d="M4 21h16" />
            <path d="M7 3l5 6 5-6" />
          </svg>
          {/* Decorative SVG: Graduation Cap */}
          <svg
            className="absolute z-0 opacity-90"
            style={{ top: "10%", right: "10%", width: "48px", height: "48px" }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#FFD700"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M22 12l-10 5L2 12" />
            <path d="M12 22V12" />
            <path d="M2 12l10-5 10 5" />
          </svg>
          {/* Decorative SVG: Triangle */}
          <svg
            className="absolute z-0"
            style={{ top: "5%", left: "5%", width: "50px", height: "50px" }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
          >
            <polygon points="50,10 90,90 10,90" fill="#FFD700" opacity="0.5" stroke="#FFD700" strokeWidth="2" />
          </svg>
          {/* Decorative SVG: Circle */}
          <svg
            className="absolute z-0"
            style={{ top: "5%", right: "5%", width: "50px", height: "50px" }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
          >
            <circle cx="50" cy="50" r="40" fill="#007BFF" opacity="0.5" stroke="#007BFF" strokeWidth="2" />
          </svg>
          {/* Decorative SVG: Pentagon */}
          <svg
            className="absolute z-0"
            style={{ bottom: "5%", left: "5%", width: "50px", height: "50px" }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
          >
            <polygon points="50,10 90,38 73,85 27,85 10,38" fill="#FFD700" opacity="0.5" stroke="#FFD700" strokeWidth="2" />
          </svg>
            <div className="absolute inset-0 bg-gradient-to-b from-[#001f3f] to-[#00172d] opacity-70"></div>
            <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white px-4">
              <h2 className="text-3xl font-bold mb-4 drop-shadow-lg">{slides[activeSlide].title}</h2>
              <p className="text-lg mb-6 drop-shadow-md">{slides[activeSlide].text}</p>
              <button
                onClick={() => navigate("/parentguardiansignup")}
                className="bg-[#e67e22] hover:bg-[#FFC107] text-white font-bold py-3 px-6 rounded shadow-md transition-all duration-300"
              >
                {slides[activeSlide].buttonText}
              </button>
              {/* Mobile Indicator Dots */}
              <div className="flex justify-center gap-2 mt-4">
                {slides.map((_, index) => (
                  <div
                    key={index}
                    onClick={() => setActiveSlide(index)}
                    className={`cursor-pointer rounded-full transition-colors duration-300 w-3 h-3 ${activeSlide === index ? 'bg-blue-600' : 'bg-blue-300'}`}
                  ></div>
                ))}
              </div>
            </div>
          </div>
          {/* Fixed Bottom Bar with Sign In and Sign Up buttons for Mobile */}
          <div
            className="fixed bottom-0 left-0 right-0 bg-white p-4 shadow-lg flex justify-around z-20"
            style={{ paddingBottom: 'calc(1rem + env(safe-area-inset-bottom))' }}
          >
            <button
              onClick={() => setShowSignIn(true)}
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full shadow-md transition-all duration-300"
            >
              Sign In
            </button>
            <Link
              to="/parentguardiansignup"
              className="border border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-white font-bold py-2 px-4 rounded-full transition-all duration-300"
            >
              Sign Up
            </Link>
          </div>

          {/* Mobile Sign In Modal – appears when "Sign In" is clicked */}
          {showSignIn && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-30">
              <div className="w-full max-w-md p-8 bg-white rounded shadow-lg relative">
                <button
                  onClick={() => setShowSignIn(false)}
                  className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 focus:outline-none transition-all duration-300"
                >
                  &#x2715;
                </button>
                <div className="text-center mb-8">
                  <h2 className="text-2xl font-bold text-gray-900">Welcome Back</h2>
                  <p className="text-gray-600 mt-2">Sign in to your account</p>
                </div>

                {errorMessage && (
                  <div className="bg-red-50 border border-red-200 text-red-700 p-4 rounded mb-6">
                    {errorMessage}
                  </div>
                )}

                <form className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Username</label>
                    <input
                      type="text"
                      name="username"
                      value={signinValues.username}
                      onChange={handleChange}
                      onKeyDown={handleEnterKeyPress}
                      className={`mt-1 block w-full rounded-full px-3 py-2 shadow-sm focus:border-blue-500 focus:ring-blue-500 transition-all duration-300 ${
                        formErrors.username ? 'border-red-500' : 'border-gray-300'
                      }`}
                    />
                    {formErrors.username && (
                      <p className="mt-1 text-sm text-red-600">{formErrors.username}</p>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Password</label>
                    <input
                      type="password"
                      name="password"
                      value={signinValues.password}
                      onChange={handleChange}
                      onKeyDown={handleEnterKeyPress}
                      className={`mt-1 block w-full rounded-full px-3 py-2 shadow-sm focus:border-blue-500 focus:ring-blue-500 transition-all duration-300 ${
                        formErrors.password ? 'border-red-500' : 'border-gray-300'
                      }`}
                    />
                    {formErrors.password && (
                      <p className="mt-1 text-sm text-red-600">{formErrors.password}</p>
                    )}
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="w-full bg-blue-600 text-white rounded-full px-4 py-2 hover:bg-blue-700 shadow-md transition-all duration-300"
                    >
                      Sign In
                    </button>
                  </div>
                  <div className="text-center">
                    <Link to="/forgotpassword" className="text-sm text-blue-600 hover:text-blue-800">
                      Forgot your password?
                    </Link>
                  </div>
                  <div className="text-center">
                    <span className="text-sm text-gray-600">
                      No account yet?{" "}
                      <Link to="/parentguardiansignup" className="text-blue-600 hover:text-blue-800">
                        Sign up now
                      </Link>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          )}

        </div>
      </div>

      {/* Sections Below Carousel – Using background color from reference (#F0F8FF) */}
      {/* How Our Platform Works Section */}
      <div className="bg-[#F0F8FF] py-12">
              <div className="container mx-auto px-4">
                <div className="text-center mb-16">
                  <h2 className="text-4xl font-bold text-gray-900 mb-6">
                    How Our Platform Works
                  </h2>
                  <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                  Give your child the <b>best chance of success</b> in the <b>UK 11+ exams</b> with our <b>simple, step-by-step preparation platform</b>.
                  </p>
                </div>
                <div className="grid md:grid-cols-2 gap-12 max-w-5xl mx-auto">
                  {/* Step 1 - Sign Up */}
                  <div className="flex items-start gap-6 group">
                    <div className="flex flex-col items-center">
                      <div className="bg-blue-100 rounded-full p-4 flex-shrink-0 shadow-sm transition-all duration-200 group-hover:bg-blue-200">
                        <div className="w-14 h-14 flex items-center justify-center">
                          <svg
                            className="w-8 h-8 text-blue-600"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path d="M16 21v-2a4 4 0 00-4-4H6a4 4 0 00-4 4v2" />
                            <circle cx="9" cy="7" r="4" />
                            <path d="M19 8v6M22 11h-6" />
                          </svg>
                        </div>
                      </div>
                      <span className="bg-blue-100 text-blue-700 text-sm font-medium px-3 py-1 rounded-full mt-2">
                        Step 1
                      </span>
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold text-gray-900">
                        Create Your Account
                      </h3>
                      <p className="text-gray-600 leading-relaxed">
                      Sign up for a <b>parent or guardian account</b> to get started. 
                      </p>
                    </div>
                  </div>
                  {/* Step 2 - Assessment */}
                  <div className="flex items-start gap-6 group">
                    <div className="flex flex-col items-center">
                      <div className="bg-blue-100 rounded-full p-4 flex-shrink-0 shadow-sm transition-all duration-200 group-hover:bg-blue-200">
                        <div className="w-14 h-14 flex items-center justify-center">
                          <svg
                            className="w-8 h-8 text-blue-600"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2" />
                            <rect x="9" y="3" width="6" height="4" rx="2" />
                            <path d="M9 14l2 2 4-4" />
                          </svg>
                        </div>
                      </div>
                      <span className="bg-blue-100 text-blue-700 text-sm font-medium px-3 py-1 rounded-full mt-2">
                        Step 2
                      </span>
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold text-gray-900">
                        Create Child Account
                      </h3>
                      <p className="text-gray-600 leading-relaxed">
                      Login and create a <b>child profile</b> with necessary details, including date of birth, to provide age-appropriate practice materials.
                      </p>
                    </div>
                  </div>
                  {/* Step 3 - Practice */}
                  <div className="flex items-start gap-6 group">
                    <div className="flex flex-col items-center">
                      <div className="bg-blue-100 rounded-full p-4 flex-shrink-0 shadow-sm transition-all duration-200 group-hover:bg-blue-200">
                        <div className="w-14 h-14 flex items-center justify-center">
                          <svg
                            className="w-8 h-8 text-blue-600"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path d="M2 3h6a4 4 0 014 4v14a3 3 0 00-3-3H2z" />
                            <path d="M22 3h-6a4 4 0 00-4 4v14a3 3 0 013-3h7z" />
                          </svg>
                        </div>
                      </div>
                      <span className="bg-blue-100 text-blue-700 text-sm font-medium px-3 py-1 rounded-full mt-2">
                        Step 3
                      </span>
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold text-gray-900">
                        Practice, Practice &amp; Practice 
                      </h3>
                      <p className="text-gray-600 leading-relaxed">
                      Access an <b>extensive library of expertly crafted practice tests</b> covering <b>Maths, English, and Verbal Reasoning.</b> Non-Verbal Reasoning is coming soon.
                      </p>
                    </div>
                  </div>
                  {/* Step 4 - Track Progress */}
                  <div className="flex items-start gap-6 group">
                    <div className="flex flex-col items-center">
                      <div className="bg-blue-100 rounded-full p-4 flex-shrink-0 shadow-sm transition-all duration-200 group-hover:bg-blue-200">
                        <div className="w-14 h-14 flex items-center justify-center">
                          <svg
                            className="w-8 h-8 text-blue-600"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path d="M3 3v18h18" />
                            <path d="M18 17l-3-3-4 4-3-3" />
                            <path d="M3 12h4l3-3 4 4 3-3h4" />
                          </svg>
                        </div>
                      </div>
                      <span className="bg-blue-100 text-blue-700 text-sm font-medium px-3 py-1 rounded-full mt-2">
                        Step 4
                      </span>
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold text-gray-900">
                        Track Progress &amp; Improve
                      </h3>
                      <p className="text-gray-600 leading-relaxed">
                      Stay informed about your child's <b>performance and progress</b> through <b>detailed analytics and personalised reports.</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

      {/* First Feature Section */}
      <div className="bg-[#FFFFFF] py-8 sm:py-12 md:py-16">
      <div className="container mx-auto px-4 max-w-7xl">
        <div className="flex flex-col lg:flex-row items-center gap-6 md:gap-8 lg:gap-12">
          {/* Left side - Interactive Demo */}
          <div className="w-full lg:w-1/2">
            <div className="bg-[#F0F8FF] rounded-xl p-3 sm:p-4 shadow-lg hover:shadow-2xl transition-all duration-300">
              <div className="bg-white rounded shadow-sm p-3 sm:p-4 md:p-6 hover:shadow-xl transition-all duration-300">
                {/* Browser-like top bar */}
                <div className="flex items-center gap-2 mb-3 sm:mb-4 border-b pb-2 sm:pb-3">
                  <div className="flex gap-1 sm:gap-1.5">
                    <div className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-red-400"></div>
                    <div className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-yellow-400"></div>
                    <div className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-green-400"></div>
                  </div>
                </div>

                {/* Search bar */}
                {/* <div className="flex flex-col sm:flex-row gap-2 sm:gap-3 mb-4 sm:mb-6">
                  <div className="flex-1 flex items-center gap-2 bg-gray-50 rounded-lg px-3 sm:px-4 py-2 border">
                    <svg className="w-4 h-4 sm:w-5 sm:h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                    <input 
                      type="text"
                      placeholder="Search practice tests..."
                      className="bg-transparent w-full outline-none text-gray-600 placeholder-gray-400 text-sm sm:text-base"
                      disabled
                    />
                  </div>
                  <button className="bg-blue-600 text-white px-4 py-2 rounded-lg flex items-center justify-center gap-2 text-sm sm:text-base">
                    <span>Search</span>
                  </button>
                </div> */}

                {/* Subject cards */}
                <div className="grid grid-cols-2 sm:grid-cols-3 gap-3 sm:gap-4">
                  <div className="bg-blue-50 p-3 sm:p-4 rounded-lg border border-blue-100">
                    <div className="flex items-center gap-2 mb-1 sm:mb-2">
                      <svg className="w-4 h-4 sm:w-5 sm:h-5 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                      </svg>
                      <h3 className="font-semibold text-blue-900 text-sm sm:text-base">Math</h3>
                    </div>
                    {/* <p className="text-xs sm:text-sm text-blue-700 pl-7">300+ questions</p> */}
                  </div>
                  
                  <div className="bg-green-50 p-3 sm:p-4 rounded-lg border border-green-100 sm:col-span-1">
                    <div className="flex items-center gap-2 mb-1 sm:mb-2">
                      <svg className="w-4 h-4 sm:w-5 sm:h-5 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                      </svg>
                      <h3 className="font-semibold text-green-900 text-sm sm:text-base">Verbal</h3>
                    </div>
                    {/* <p className="text-xs sm:text-sm text-green-700 pl-7">200+ questions</p> */}
                  </div>
                  
                  <div className="bg-purple-50 p-3 sm:p-4 rounded-lg border border-purple-100">
                    <div className="flex items-center gap-2 mb-1 sm:mb-2">
                      <svg className="w-4 h-4 sm:w-5 sm:h-5 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                      </svg>
                      <h3 className="font-semibold text-purple-900 text-sm sm:text-base">English</h3>
                    </div>
                    {/* <p className="text-xs sm:text-sm text-purple-700 pl-7">250+ questions</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right side - Feature Description */}
          <div className="w-full lg:w-1/2 space-y-4 sm:space-y-6 mt-6 lg:mt-0">
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-900">
              Comprehensive Test Library
            </h2>
            <div className="space-y-3 sm:space-y-4">
              <div className="flex items-start gap-2">
                <svg className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <p className="text-base sm:text-lg md:text-xl text-gray-600">
                  Practice with a vast collection of UK 11+ exam questions tailored for 
                  Maths, English, and Verbal Reasoning.
                </p>
              </div>

              <div className="flex items-start gap-2">
                <svg className="w-5 h-5 text-yellow-500 mt-1 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
                </svg>
                <p className="text-base sm:text-lg md:text-xl text-gray-600">
                  Designed to cover all exam boards including CEM, GL, and 
                  independent school formats.
                </p>
              </div>

              <div className="flex items-start gap-2">
                <svg className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <p className="text-base sm:text-lg md:text-xl text-gray-600">
                  Structured from beginner to advanced levels to match your child's 
                  learning journey.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

      {/* Second Feature Section */}
      <div className="bg-[#F0F8FF] py-6 sm:py-8 md:py-12">
      <div className="container mx-auto px-4 max-w-7xl">
        <div className="flex flex-col lg:flex-row-reverse items-center gap-6 md:gap-8 lg:gap-12">
          {/* Right side - Interactive Demo */}
          <div className="w-full lg:w-3/5">
            <div className="bg-[#F0F8FF] rounded-xl p-3 sm:p-4 shadow-lg hover:shadow-2xl transition-all duration-300">
              <div className="bg-white rounded shadow-sm p-3 sm:p-4 md:p-5 hover:shadow-xl transition-all duration-300">
                {/* Browser-like top bar */}
                <div className="flex items-center gap-2 mb-3 sm:mb-4 border-b pb-2 sm:pb-3">
                  <div className="flex gap-1 sm:gap-1.5">
                    <div className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-red-400"></div>
                    <div className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-yellow-400"></div>
                    <div className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-green-400"></div>
                  </div>
                </div>

                {/* Question Section */}
                <div className="mb-4">
                  <div className="bg-gray-50 rounded-lg p-3 mb-3">
                    <p className="text-gray-700 font-medium mb-2">Question 1:</p>
                    <p className="text-gray-600 mb-3">What is 15% of 80?</p>
                    <div className="space-y-2">
                      <div className="flex items-center gap-2 text-sm">
                        <span className="flex-shrink-0 w-6 h-6 rounded-full bg-green-100 text-green-600 flex items-center justify-center">✓</span>
                        <span className="text-green-600 font-medium">12 - Correct!</span>
                      </div>
                    </div>
                  </div>
                  
                  <div className="bg-blue-50 rounded-lg p-3">
                    <p className="text-blue-800 font-medium mb-2">Explanation:</p>
                    <div className="text-blue-600 text-sm">
                      <p>15% = 15/100 = 0.15</p>
                      <p>0.15 × 80 = 12</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Left side - Feature Description */}
          <div className="w-full lg:w-2/5 space-y-4 sm:space-y-6 mt-6 lg:mt-0">
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-900">
              Instant Marking System
            </h2>
            <div className="space-y-4">
              <div className="flex items-start gap-2">
                <svg className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <p className="text-base sm:text-lg md:text-xl text-gray-600">
                  Get immediate feedback after each question attempt, ensuring faster learning.
                </p>
              </div>

              <div className="flex items-start gap-2">
                <svg className="w-5 h-5 text-blue-500 mt-1 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <p className="text-base sm:text-lg md:text-xl text-gray-600">
                  Detailed explanations provided for each answer to strengthen understanding.
                </p>
              </div>

              <div className="flex items-start gap-2">
                <svg className="w-5 h-5 text-yellow-500 mt-1 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <p className="text-base sm:text-lg md:text-xl text-gray-600">
                  Saves time with automated grading, so students can focus on improving their scores.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

      {/* Third Feature Section */}
      <div className="bg-[#FFFFFF] py-8 sm:py-12 md:py-16">
      <div className="container mx-auto px-4 max-w-7xl">
        <div className="flex flex-col lg:flex-row items-center gap-6 md:gap-8 lg:gap-12">
          {/* Left side - Interactive Demo */}
          <div className="w-full lg:w-1/2">
            <div className="bg-[#F0F8FF] rounded-xl p-3 sm:p-4 shadow-lg hover:shadow-2xl transition-all duration-300">
              <div className="bg-white rounded shadow-sm p-3 sm:p-4 md:p-6 hover:shadow-xl transition-all duration-300">
                {/* Browser-like top bar */}
                <div className="flex items-center gap-2 mb-3 sm:mb-4 border-b pb-2 sm:pb-3">
                  <div className="flex gap-1 sm:gap-1.5">
                    <div className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-red-400"></div>
                    <div className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-yellow-400"></div>
                    <div className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-green-400"></div>
                  </div>
                </div>

                {/* Performance Overview */}
                <div className="mb-6">
                  <h3 className="text-lg font-semibold text-gray-800 mb-4">Performance Overview</h3>
                  
                  {/* Subject Performance */}
                  <div className="space-y-4">
                    <div>
                      <div className="flex justify-between mb-1">
                        <span className="text-sm font-medium text-blue-600">Today</span>
                        <span className="text-sm text-blue-600">92%</span>
                      </div>
                      <div className="h-2 bg-gray-100 rounded-full">
                        <div className="h-2 bg-blue-500 rounded-full w-[92%]"></div>
                      </div>
                    </div>
                    
                    <div>
                      <div className="flex justify-between mb-1">
                        <span className="text-sm font-medium text-purple-600">Yesterday</span>
                        <span className="text-sm text-purple-600">85%</span>
                      </div>
                      <div className="h-2 bg-gray-100 rounded-full">
                        <div className="h-2 bg-purple-500 rounded-full w-[85%]"></div>
                      </div>
                    </div>
                    
                    <div>
                      <div className="flex justify-between mb-1">
                        <span className="text-sm font-medium text-green-600">2 Days Ago</span>
                        <span className="text-sm text-green-600">78%</span>
                      </div>
                      <div className="h-2 bg-gray-100 rounded-full">
                        <div className="h-2 bg-green-500 rounded-full w-[78%]"></div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Recent Activity */}
                {/* <div className="bg-gray-50 rounded-lg p-4">
                  <h4 className="text-sm font-semibold text-gray-700 mb-3">Recent Improvements</h4>
                  <div className="space-y-2">
                    <div className="flex items-center gap-2">
                      <svg className="w-4 h-4 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                      </svg>
                      <span className="text-sm text-gray-600">+15% in Problem Solving</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <svg className="w-4 h-4 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                      </svg>
                      <span className="text-sm text-gray-600">+12% in Comprehension</span>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          {/* Right side - Feature Description */}
          <div className="w-full lg:w-1/2 space-y-4 sm:space-y-6 mt-6 lg:mt-0">
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-900">
              Progress Tracking
            </h2>
            <div className="space-y-4">
              <div className="flex items-start gap-2">
                <svg className="w-5 h-5 text-blue-500 mt-1 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                </svg>
                <p className="text-base sm:text-lg md:text-xl text-gray-600">
                  Visual progress reports to help parents track their child's strengths and weaknesses.
                </p>
              </div>

              <div className="flex items-start gap-2">
                <svg className="w-5 h-5 text-purple-500 mt-1 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                </svg>
                <p className="text-base sm:text-lg md:text-xl text-gray-600">
                  Personalised performance insights with subject & topic-wise breakdowns and improvement areas.
                </p>
              </div>

              <div className="flex items-start gap-2">
                <svg className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                </svg>
                <p className="text-base sm:text-lg md:text-xl text-gray-600">
                  Identify trends and set realistic goals based on past performance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

      {/* Fourth Feature Section */}
      <div className="bg-[#F0F8FF] py-8 sm:py-12 md:py-16">
      <div className="container mx-auto px-4 max-w-7xl">
        <div className="flex flex-col lg:flex-row-reverse items-center gap-6 md:gap-8 lg:gap-12">
          {/* Right side - Interactive Demo */}
          <div className="w-full lg:w-1/2">
            <div className="bg-[#F0F8FF] rounded-xl p-3 sm:p-4 shadow-lg hover:shadow-2xl transition-all duration-300">
              <div className="bg-white rounded shadow-sm p-3 sm:p-4 md:p-6 hover:shadow-xl transition-all duration-300">
                {/* Browser-like top bar */}
                <div className="flex items-center gap-2 mb-3 sm:mb-4 border-b pb-2 sm:pb-3">
                  <div className="flex gap-1 sm:gap-1.5">
                    <div className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-red-400"></div>
                    <div className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-yellow-400"></div>
                    <div className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-green-400"></div>
                  </div>
                </div>

                {/* Simple Search Interface */}
                {/* <div className="flex gap-2 mb-6">
                  <div className="flex-1 flex items-center gap-2 bg-gray-50 rounded-lg px-3 sm:px-4 py-2 border">
                    <svg className="w-4 h-4 sm:w-5 sm:h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                    <input 
                      type="text"
                      placeholder="Search practice tests..."
                      className="bg-transparent w-full outline-none text-gray-600 placeholder-gray-400 text-sm sm:text-base"
                      disabled
                    />
                  </div>
                </div> */}

                {/* Filter Options */}
                <div className="grid grid-cols-2 gap-3 mb-6">
                  <div className="bg-gray-50 p-3 rounded-lg border border-gray-100">
                    <div className="flex items-center justify-between">
                      <span className="text-sm text-gray-600">Subject</span>
                      <select className="text-sm bg-transparent border-none outline-none text-gray-600">
                        <option>Math</option>
                      </select>
                    </div>
                  </div>
                  {/* <div className="bg-gray-50 p-3 rounded-lg border border-gray-100">
                    <div className="flex items-center justify-between">
                      <span className="text-sm text-gray-600">Time</span>
                      <select className="text-sm bg-transparent border-none outline-none text-gray-600">
                        <option>30 mins</option>
                      </select>
                    </div>
                  </div> */}
                  <div className="bg-gray-50 p-3 rounded-lg border border-gray-100">
                    <div className="flex items-center justify-between">
                      <span className="text-sm text-gray-600">Questions</span>
                      <select className="text-sm bg-transparent border-none outline-none text-gray-600">
                        <option>25</option>
                      </select>
                    </div>
                  </div>
                  {/* <div className="bg-gray-50 p-3 rounded-lg border border-gray-100">
                    <div className="flex items-center justify-between">
                      <span className="text-sm text-gray-600">Topics</span>
                      <select className="text-sm bg-transparent border-none outline-none text-gray-600">
                        <option>All</option>
                      </select>
                    </div>
                  </div> */}
                </div>

                {/* Start Button */}
                <button className="w-full bg-blue-600 text-white py-2 rounded-lg text-sm font-medium hover:bg-blue-700 transition-colors">
                  Create Practice Test
                </button>
              </div>
            </div>
          </div>

          {/* Left side - Feature Description */}
          <div className="w-full lg:w-1/2 space-y-4 sm:space-y-6 mt-6 lg:mt-0">
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-900">
              Customisable Practice Sessions
            </h2>
            <div className="space-y-4">
              <div className="flex items-start gap-2">
                <svg className="w-5 h-5 text-blue-500 mt-1 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
                </svg>
                <p className="text-base sm:text-lg md:text-xl text-gray-600">
                  Create personalised tests based on topic, difficulty, and time limits.
                </p>
              </div>

              <div className="flex items-start gap-2">
                <svg className="w-5 h-5 text-purple-500 mt-1 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                </svg>
                <p className="text-base sm:text-lg md:text-xl text-gray-600">
                  Adaptive learning adjusts question difficulty based on past performance.
                </p>
              </div>

              <div className="flex items-start gap-2">
                <svg className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                </svg>
                <p className="text-base sm:text-lg md:text-xl text-gray-600">
                  Mix subjects or focus on specific weak areas for targeted improvement.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <Footer />
    </div>
  );
};

export default LandingPage;
