import React from 'react';
import { Helmet } from 'react-helmet';

const TermsAndConditions = () => {
  return (
    <>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>11 + Practice Papers | Terms & Conditions | Practice Papers</title>
        <meta 
          name="description" 
          content="Read the Terms & Conditions for using Practice Papers, your trusted platform for UK 11+ exam preparation." 
        />
        <meta 
          name="keywords" 
          content="11 plus exams, grammar schools, UK education, CEM, GL assessment, practice tests, terms and conditions" 
        />
        <meta name="author" content="Practice Papers" />
        <style>{`
          /* ==== Global Reset & Base Styles ==== */
          * { margin: 0; padding: 0; box-sizing: border-box; }
          a {
            color: #007BFF;
            text-decoration: none;
            font-weight: bold;
          }
          a:hover {
            color: #0056b3;
            text-decoration: underline;
          }
          
          
          
          /* ==== Terms & Conditions ==== */
          .terms-container {
            max-width: 900px;
            margin: 80px auto 40px;
            padding: 30px;
            background: white;
            box-shadow: 0 4px 10px rgba(0,0,0,0.1);
            border-radius: 8px;
          }
          h1 { color: #007BFF; text-align: center; }
          .updated-date { text-align: center; color: gray; font-size: 0.9em; margin-bottom: 20px; }
          h2 { margin-top: 20px; color: #0056b3; font-size: 18px }
          ul { margin: 10px 0; padding-left: 20px; }
          ul li { margin-bottom: 5px; }
          
        
          
          /* ==== Responsive Styles ==== */
          @media (max-width: 768px) {
            .features-container { flex-direction: column; align-items: center; }
            .feature { width: 80%; }
            .carousel-slide .slide-content h2 { font-size: 2.2em; }
            .carousel-slide .slide-content p { font-size: 1em; }
          }
        `}</style>
      </Helmet>

      <main className="terms-container">
        <h1>11 + Practice Papers - Terms & Conditions</h1>
        <p className="updated-date">Last Updated: January 2025</p>

        <section>
          <h2>1. Introduction</h2>
          <p>
            Welcome to Practice Papers! These Terms and Conditions ("Terms") govern your access to and use of the&nbsp;
            <a href="https://practicepapers.net/">Practice Papers website</a> (the "Website") and all related services (the "Services").
          </p>
          <br />
          <p>
            By accessing or using our Website and Services, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, please do not use our Website or Services.
          </p>
        </section>

        <section>
          <h2>2. User Eligibility</h2>
          <ul>
            <li>
              The Website and Services are intended for use by parents or guardians of children and the children preparing for the UK 11+ entrance exam.
            </li>
            <li>
              As a parent or guardian, by signing up for the Website, you confirm that you are at least 18 years old and have the legal authority to enter into a contract for yourself and on behalf of the child/children under your care.
            </li>
            <li>
              Our <a href="privacy-policy">Privacy Policy</a> sets out how we collect, process, and store personal data provided by you. We use cookies to maintain your sign-in or sign-up state and to enhance user experience.
            </li>
            <li>
              If you do not meet these criteria, you must not use the Website or Services.
            </li>
          </ul>
        </section>

        <section>
          <h2>3. Registration & Account Creation</h2>
          <p>To access our practice tests, you must register for an account by providing:</p>
          <ul>
            <li>Your full name</li>
            <li>A valid email address</li>
            <li>A valid postcode</li>
          </ul>
          <p>Upon successful registration, you can create a child account, providing:</p>
          <ul>
            <li>The child’s date of birth (used to provide age-appropriate practice materials)</li>
          </ul>
          <p><strong>Account Security</strong></p>
          <ul>
            <li>You must keep your login details confidential.</li>
            <li>You are responsible for all activity on your account.</li>
            <li>
              If you suspect unauthorised access, please <a href="https://practicepapers.net/contact-us">Contact us</a> immediately.
            </li>
          </ul>
        </section>

        <section>
          <h2>4. Your Obligations</h2>
          <p>By using the Website, you agree to:</p>
          <ul>
            <li>Provide accurate, up-to-date information when signing up.</li>
            <li>Use the Services responsibly, only for personal, non-commercial educational purposes.</li>
            <li>Not share your login details with others or allow unauthorised access.</li>
            <li>Not attempt to copy, scrape, or modify any part of the Website or Services.</li>
            <li>Not engage in fraudulent activities, including impersonation or account misuse.</li>
            <li>
              Follow all applicable laws and regulations when using our Website and Services.
            </li>
          </ul>
          <p>Failure to follow these obligations may result in termination of your account.</p>
        </section>

        <section>
          <h2>5. Use of the Website</h2>
          <p>The Website and Services are provided for your personal, non-commercial use.</p>
          <p>You agree not to:</p>
          <ul>
            <li>Use the Website for any illegal, fraudulent, or unauthorised purposes.</li>
            <li>Modify, copy, or distribute any content without permission.</li>
            <li>Attempt to hack, disrupt, or interfere with our Services.</li>
            <li>Upload or share harmful, offensive, or misleading content.</li>
          </ul>
        </section>

        <section>
          <h2>6. Intellectual Property Rights</h2>
          <p>All content available on the Website, including but not limited to:</p>
          <ul>
            <li>Practice tests</li>
            <li>Questions, answers, and explanations</li>
            <li>Design, branding, and software</li>
          </ul>
          <p>
            is the exclusive property of Practice Papers or our licensors and is protected under intellectual property laws. You shall not:
          </p>
          <ul>
            <li>Reproduce, modify, distribute, or publicly display any content without our written consent.</li>
            <li>Use any of our content for commercial purposes.</li>
          </ul>
          <p><strong>Content Similarity & Reporting Concerns:</strong></p>
          <p>
            Practice Papers makes every effort to ensure that all content, including practice questions, study materials, and other resources, is original and does not infringe upon third-party intellectual property rights. However, due to the nature of educational content and commonly tested concepts, some material may appear similar to resources available elsewhere. If you believe that any content on our Website infringes upon your intellectual property rights, please <a href="https://practicepapers.net/contact-us">Contact us</a> with relevant details. We take such concerns seriously and will make every effort to address them in a timely manner.
          </p>
        </section>

        <section>
          <h2>7. Privacy & Data Protection</h2>
          <p>
            Your personal data is collected, used, and stored in accordance with our <a href="privacy-policy">Privacy Policy</a>.
          </p>
          <p>Key points:</p>
          <ul>
            <li>
              We collect only necessary data (name, email, postcode, child’s date of birth) to provide you with access to our Services and to improve user experience.
            </li>
            <li>We use cookies to maintain your sign-in state and to enhance user experience.</li>
            <li>We do not share your personal data with third parties for marketing purposes.</li>
            <li>We take data security seriously and have implemented measures to protect your data.</li>
          </ul>
        </section>

        <section>
          <h2>8. Disclaimer of Warranties</h2>
          <p>
            Our Website and Services are provided "as is" and "as available", without warranties of any kind.
          </p>
          <p>We do not guarantee:</p>
          <ul>
            <li>That the Website will be error-free, 100% secure, always available or uninterrupted.</li>
            <li>That the content will be accurate, reliable, or suitable for your needs.</li>
            <li>That any defects or errors will be corrected.</li>
            <li>That the Website will be free from viruses, security threats, or technical failures.</li>
          </ul>
          <p>
            To the fullest extent permitted by law, Practice Papers disclaims all implied warranties, including merchantability, fitness for a particular purpose, and non-infringement.
          </p>
        </section>

        <section>
          <h2>9. Limitation of Liability</h2>
          <p>To the fullest extent permitted by law, Practice Papers will not be liable for:</p>
          <ul>
            <li>
              Any direct, indirect, incidental, or consequential damages arising from your use of the Website or Services.
            </li>
            <li>
              Any loss of profits, revenue, data, business interruption or goodwill.
            </li>
            <li>Any indirect, incidental, or consequential damages.</li>
            <li>
              Any issues arising from third-party content, services, or advertisements.
            </li>
          </ul>
        </section>

        <section>
          <h2>10. Indemnification</h2>
          <p>
            You agree to indemnify and hold harmless Practice Papers, its affiliates, and employees from any claims, damages, losses, and expenses (including legal fees) arising from:
          </p>
          <ul>
            <li>Your use of the Website or Services.</li>
            <li>Your violation of these Terms.</li>
            <li>Your violation of any third-party rights.</li>
            <li>Any misuse of content from the Website.</li>
          </ul>
        </section>

        <section>
          <h2>11. Termination of Access</h2>
          <ul>
            <li>
              Practice Papers reserves the right to terminate or suspend your access to the Website and Services at any time, without notice, for any reason.
            </li>
            <li>
              Upon termination, your right to use the Services immediately ceases and you must cease all use of the Website and Services and destroy any downloaded or printed materials.
            </li>
          </ul>
        </section>

        <section>
          <h2>12. Governing Law & Dispute Resolution</h2>
          <ul>
            <li>These Terms are governed by the laws of England and Wales.</li>
            <li>
              Any disputes arising from these Terms shall be resolved through negotiation first, with the parties agreeing to a mutually acceptable resolution.
            </li>
            <li>If a resolution is not reached, disputes will be handled in UK courts.</li>
          </ul>
        </section>

        <section>
          <h2>13. Changes to Terms</h2>
          <p>
            Practice Papers reserves the right to update or modify these Terms at any time. If changes occur, we’ll notify you via email or update this page.
          </p>
          <p>
            It is your responsibility to review these Terms periodically. Your continued use of the Website and Services after any changes indicates your acceptance of the new Terms.
          </p>
        </section>

        <section>
          <h2>14. Contact Us</h2>
          <p>
            If you have any questions or concerns about these Terms, please <a href="https://practicepapers.net/contact-us">Contact us</a> for assistance.
          </p>
        </section>
      </main>
    </>
  );
};

export default TermsAndConditions;
