import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faBars,
  faTimes,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import useSignOut from "./utils";

const Header = () => {
  const navigate = useNavigate();
  const SignOut = useSignOut();

  // State for password reset form
  const [PTInput, setPTInput] = useState({
    ResetChild: "",
    new_password: "",
    confirmpassword: "",
    performceArea: "",
  });

  // State for user data and loading
  const [state, setState] = useState({
    token: localStorage.getItem("authToken") || null,
    userType: localStorage.getItem("userType") || null,
    firstName: localStorage.getItem("firstName") || null,
    children: localStorage.getItem("children")
      ? JSON.parse(localStorage.getItem("children"))
      : [],
    loading: true,
  });

  // Other UI state variables
  const [showModal, setShowModal] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [displayMessage, setDisplayMessage] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [questionBankDropdownOpen, setQuestionBankDropdownOpen] =
    useState(false);
  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);

  // Refs for dropdown containers
  const questionBankRef = useRef(null);
  const profileRef = useRef(null);

  // Close dropdowns if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        questionBankDropdownOpen &&
        questionBankRef.current &&
        !questionBankRef.current.contains(event.target)
      ) {
        setQuestionBankDropdownOpen(false);
      }
      if (
        profileDropdownOpen &&
        profileRef.current &&
        !profileRef.current.contains(event.target)
      ) {
        setProfileDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () =>
      document.removeEventListener("mousedown", handleClickOutside);
  }, [questionBankDropdownOpen, profileDropdownOpen]);

  // When user selects a child and enters new password, call the API
  useEffect(() => {
    const data = {
      email_or_username: PTInput.ResetChild,
      new_password: PTInput.new_password,
      confirm_password: PTInput.confirmpassword,
    };
    if (isReset && PTInput.ResetChild !== "") {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_API_URL}/forgot-password/`,
          data
        )
        .then((response) => {
          setIsReset(false);
          setDisplayMessage(response.data.message);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsReset(false);
        });
    } else {
      setIsReset(false);
      setDisplayMessage("Child field cannot be blank");
    }
  }, [PTInput.ResetChild, isReset]);

  // Create arrays for names and usernames (if children exist)
  const namesArray =
    Array.isArray(state.children) && state.children.map((user) => user[1]);
  const usernameArray =
    Array.isArray(state.children) && state.children.map((user) => user[2]);

  // Update state from local storage when it changes
  useEffect(() => {
    setState({
      token: localStorage.getItem("authToken") || null,
      userType: localStorage.getItem("userType") || null,
      firstName: localStorage.getItem("firstName") || null,
      children: localStorage.getItem("children")
        ? JSON.parse(localStorage.getItem("children"))
        : [],
      loading: false,
    });
  }, [
    localStorage.getItem("authToken"),
    localStorage.getItem("userType"),
    localStorage.getItem("firstName"),
    localStorage.getItem("children"),
  ]);

  // Handle form input changes
  const onInputChange = (e) => {
    const { name, value } = e.target;
    setPTInput((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const resetPassword = (e) => {
    e.preventDefault();
    setIsReset(true);
  };

  const modal = () => {
    setShowModal(true);
    setPTInput((prevFormData) => ({
      ...prevFormData,
      ResetChild: "",
    }));
  };

  return (
    <>
      {/* NAVBAR */}
      <header className="bg-blue-600 text-white">
        <div className="max-w-0xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Flex container with items centered vertically and spaced between */}
          <div className="flex justify-between items-center h-15">
            {/* Brand (Left aligned) */}
            <div
              className="flex-shrink-0 flex items-center cursor-pointer"
              onClick={() => navigate("/")}
            >
              <span className="font-bold text-xl">Practice Papers</span>
            </div>

            {/* Mobile menu button */}
            <div className="flex items-center sm:hidden">
              <button
                onClick={() => setMenuOpen(!menuOpen)}
                className="p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              >
                {menuOpen ? (
                  <FontAwesomeIcon icon={faTimes} className="h-6 w-6" />
                ) : (
                  <FontAwesomeIcon icon={faBars} className="h-6 w-6" />
                )}
              </button>
            </div>

            {/* Desktop menu */}
            <nav className="hidden sm:flex sm:items-center sm:space-x-4">
              {state.userType === "parent" && (
                <button
                  onClick={() => navigate("/home")}
                  className="hover:bg-blue-700 px-3 py-2 rounded-md text-sm font-medium"
                >
                  Home
                </button>
              )}

              {(state.userType === "child" ||
                state.userType === "qbAdmin" ||
                state.userType === "super admin") && (
                <button
                  onClick={() => navigate("/ppstartpage")}
                  className="hover:bg-blue-700 px-3 py-2 rounded-md text-sm font-medium"
                >
                  Practice Tests
                </button>
              )}

              {(state.userType === "qbAdmin" ||
                state.userType === "super admin") && (
                <>
                  <div ref={questionBankRef} className="relative">
                    <button
                      onClick={() =>
                        setQuestionBankDropdownOpen(
                          !questionBankDropdownOpen
                        )
                      }
                      className="hover:bg-blue-700 px-3 py-2 rounded-md text-sm font-medium flex items-center"
                    >
                      Manage Question Bank
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="ml-1 h-4 w-4"
                      />
                    </button>
                    {questionBankDropdownOpen && (
                      <div className="absolute left-0 mt-2 w-48 bg-white text-black rounded-md shadow-lg z-20">
                        <button
                          onClick={() => {
                            setQuestionBankDropdownOpen(false);
                            navigate("/addquestions");
                          }}
                          className="block px-4 py-2 hover:bg-gray-100 w-full text-left"
                        >
                          Add new questions
                        </button>
                        <button
                          onClick={() => {
                            setQuestionBankDropdownOpen(false);
                            navigate("/modifyquestions");
                          }}
                          className="block px-4 py-2 hover:bg-gray-100 w-full text-left"
                        >
                          Modify questions
                        </button>
                        <button
                          onClick={() => {
                            setQuestionBankDropdownOpen(false);
                            navigate("/DuplicateValues");
                          }}
                          className="block px-4 py-2 hover:bg-gray-100 w-full text-left"
                        >
                          Find duplicates
                        </button>
                        <button
                          onClick={() => {
                            setQuestionBankDropdownOpen(false);
                            navigate("/QBDashboard");
                          }}
                          className="block px-4 py-2 hover:bg-gray-100 w-full text-left"
                        >
                          QB Dashboard
                        </button>
                      </div>
                    )}
                  </div>
                  <button
                    onClick={() => navigate("/QBDashboard")}
                    className="hover:bg-blue-700 px-3 py-2 rounded-md text-sm font-medium"
                  >
                    QB Dashboard
                  </button>
                </>
              )}

              {(state.userType === "qbAdmin" ||
                state.userType === "super admin") && (
                <button
                  onClick={() => navigate("/practiceTestsList")}
                  className="hover:bg-blue-700 px-3 py-2 rounded-md text-sm font-medium"
                >
                  Practice Test Submissions
                </button>
              )}

              {state.userType === "qbDeveloper" && (
                <button
                  onClick={() => navigate("/mathsquestions")}
                  className="hover:bg-blue-700 px-3 py-2 rounded-md text-sm font-medium"
                >
                  View Maths Questions
                </button>
              )}
            </nav>

            {/* Profile dropdown (Right aligned) */}
            {state.token && (
              <div ref={profileRef} className="relative">
                <button
                  onClick={() =>
                    setProfileDropdownOpen(!profileDropdownOpen)
                  }
                  className="flex items-center hover:bg-blue-700 px-3 py-2 rounded-md text-sm font-medium focus:outline-none"
                >
                  <FontAwesomeIcon icon={faUser} className="mr-1" />{" "}
                  {state.firstName}
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className="ml-1 h-4 w-4"
                  />
                </button>
                {profileDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white text-black rounded-md shadow-lg z-20">
                    <button
                      onClick={() => {
                        setProfileDropdownOpen(false);
                        SignOut();
                      }}
                      className="block px-4 py-2 hover:bg-gray-100 w-full text-left"
                    >
                      Sign Out
                    </button>
                    <button
                      disabled
                      className="block px-4 py-2 hover:bg-gray-100 w-full text-left opacity-50 cursor-not-allowed"
                    >
                      Manage Your Profile (Coming soon)
                    </button>
                    {state.userType !== "child" &&
                      state.children.length > 0 && (
                        <button
                          onClick={() => {
                            setProfileDropdownOpen(false);
                            modal();
                          }}
                          className="block px-4 py-2 hover:bg-gray-100 w-full text-left"
                        >
                          Change your child's account
                        </button>
                      )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        {/* Mobile menu panel */}
        {menuOpen && (
          <nav className="sm:hidden px-2 pt-2 pb-3 space-y-1">
            {state.userType === "parent" && (
              <button
                onClick={() => {
                  setMenuOpen(false);
                  navigate("/home");
                }}
                className="block w-full text-left hover:bg-blue-700 px-3 py-2 rounded-md text-sm font-medium"
              >
                Home
              </button>
            )}
            {(state.userType === "child" ||
              state.userType === "qbAdmin" ||
              state.userType === "super admin") && (
              <button
                onClick={() => {
                  setMenuOpen(false);
                  navigate("/ppstartpage");
                }}
                className="block w-full text-left hover:bg-blue-700 px-3 py-2 rounded-md text-sm font-medium"
              >
                Practice Tests
              </button>
            )}
            {(state.userType === "qbAdmin" ||
              state.userType === "super admin") && (
              <>
                <div className="relative">
                  <button
                    onClick={() =>
                      setQuestionBankDropdownOpen(
                        !questionBankDropdownOpen
                      )
                    }
                    className="block w-full text-left hover:bg-blue-700 px-3 py-2 rounded-md text-sm font-medium flex items-center justify-between"
                  >
                    <span>Manage Question Bank</span>
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className="ml-1 h-4 w-4"
                    />
                  </button>
                  {questionBankDropdownOpen && (
                    <div className="mt-1 space-y-1">
                      <button
                        onClick={() => {
                          setQuestionBankDropdownOpen(false);
                          setMenuOpen(false);
                          navigate("/addquestions");
                        }}
                        className="block w-full text-left hover:bg-blue-700 px-3 py-2 rounded-md text-sm font-medium"
                      >
                        Add new questions
                      </button>
                      <button
                        onClick={() => {
                          setQuestionBankDropdownOpen(false);
                          setMenuOpen(false);
                          navigate("/modifyquestions");
                        }}
                        className="block w-full text-left hover:bg-blue-700 px-3 py-2 rounded-md text-sm font-medium"
                      >
                        Modify questions
                      </button>
                      <button
                        onClick={() => {
                          setQuestionBankDropdownOpen(false);
                          setMenuOpen(false);
                          navigate("/DuplicateValues");
                        }}
                        className="block w-full text-left hover:bg-blue-700 px-3 py-2 rounded-md text-sm font-medium"
                      >
                        Find duplicates
                      </button>
                      <button
                        onClick={() => {
                          setQuestionBankDropdownOpen(false);
                          setMenuOpen(false);
                          navigate("/QBDashboard");
                        }}
                        className="block w-full text-left hover:bg-blue-700 px-3 py-2 rounded-md text-sm font-medium"
                      >
                        QB Dashboard
                      </button>
                    </div>
                  )}
                </div>
                <button
                  onClick={() => {
                    setMenuOpen(false);
                    navigate("/QBDashboard");
                  }}
                  className="block w-full text-left hover:bg-blue-700 px-3 py-2 rounded-md text-sm font-medium"
                >
                  QB Dashboard
                </button>
              </>
            )}
            {(state.userType === "qbAdmin" ||
              state.userType === "super admin") && (
              <button
                onClick={() => {
                  setMenuOpen(false);
                  navigate("/practiceTestsList");
                }}
                className="block w-full text-left hover:bg-blue-700 px-3 py-2 rounded-md text-sm font-medium"
              >
                Practice Test Submissions
              </button>
            )}
            {state.userType === "qbDeveloper" && (
              <button
                onClick={() => {
                  setMenuOpen(false);
                  navigate("/mathsquestions");
                }}
                className="block w-full text-left hover:bg-blue-700 px-3 py-2 rounded-md text-sm font-medium"
              >
                View Maths Questions
              </button>
            )}
            {/* Profile options for mobile */}
            {state.token && (
              <div className="border-t border-blue-700 mt-2 pt-2">
                <button
                  onClick={() =>
                    setProfileDropdownOpen(!profileDropdownOpen)
                  }
                  className="w-full text-left hover:bg-blue-700 px-3 py-2 rounded-md text-sm font-medium flex items-center justify-between"
                >
                  <span>
                    <FontAwesomeIcon icon={faUser} className="mr-1" />{" "}
                    {state.firstName}
                  </span>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className="h-4 w-4"
                  />
                </button>
              </div>
            )}
          </nav>
        )}
      </header>

      {/* MODAL: Reset Child Password */}
      {showModal && (
        <div className="fixed inset-0 z-30 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-black opacity-50"></div>
            </div>
            <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full z-40">
              <div className="px-4 py-5 sm:p-6">
                <div className="flex justify-between items-center border-b pb-2">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Change password for your child
                  </h3>
                  <button
                    onClick={() => setShowModal(false)}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
                <div className="mt-4">
                  <select
                    name="ResetChild"
                    value={PTInput.ResetChild}
                    onChange={onInputChange}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                  >
                    <option value=""></option>
                    {namesArray &&
                      namesArray.map((name, index) => (
                        <option key={index} value={usernameArray[index]}>
                          {name}
                        </option>
                      ))}
                  </select>
                </div>
                {PTInput.ResetChild !== "" && (
                  <>
                    <div className="mt-4">
                      <label className="block text-sm font-medium text-gray-700">
                        Enter new password
                      </label>
                      <input
                        type="password"
                        name="new_password"
                        value={PTInput.new_password}
                        onChange={onInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                    <div className="mt-4">
                      <label className="block text-sm font-medium text-gray-700">
                        Confirm password
                      </label>
                      <input
                        type="password"
                        name="confirmpassword"
                        value={PTInput.confirmpassword}
                        onChange={onInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                    <div className="mt-4">
                      <button
                        onClick={resetPassword}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none sm:text-sm"
                      >
                        Reset Password
                      </button>
                    </div>
                  </>
                )}
                {displayMessage && (
                  <div className="mt-4 text-sm text-green-600">
                    {displayMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
