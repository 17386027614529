import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Alert,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import SuccessMessage from "../Static/SuccessMessage";
import TickCard from "../Static/TickCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faExclamationCircle, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import '../CssFiles/ParentGuardianSignUp.css';
import { useLoading } from "../Static/LoadingContext";

const ParentGuardianSignUp = () => {
  useEffect(() => {
    const csrftoken = Cookies.get("csrftoken");
    axios.defaults.headers.common["X-CSRFToken"] = csrftoken;
  }, []);

  const [usernameTooltipOpen, setUsernameTooltipOpen] = useState(false);
  const [emailTooltipOpen, setEmailTooltipOpen] = useState(false);
  const [postcodeTooltipOpen, setPostcodeTooltipOpen] = useState(false);

  const usernameTooltip = (
    <Tooltip id="username-tooltip">
      Enter your desired username here.
    </Tooltip>
  );
  const emailTooltip = (
    <Tooltip id="email-tooltip" style={{ textAlign: "left" }}>
      Enter your email here.
    </Tooltip>
  );
  const postcodeTooltip = (
    <Tooltip id="postcode-tooltip">
      Our platform requires essential details such as <b>postcode</b> to provide a <b>customised experience</b> based on <b>your location, available schools, and exam format.</b>
    </Tooltip>
  );

  const { setIsLoading } = useLoading();
  const initialValues = {
    user: {
      username: "",
      email: "",
      password: "",
      confirmpassword: "",
    },
    first_name: "",
    last_name: "",
    postcode: "",
    tandc: "",
  };
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const [pgSignupFormData, setPgSignupFormData] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [submitErrors, setSubmitErrors] = useState("");
  const [successMessage, setSuccessMessage] = useState(null);

  const isFormFilled =
    pgSignupFormData.first_name &&
    pgSignupFormData.last_name &&
    pgSignupFormData.user.username &&
    pgSignupFormData.postcode &&
    pgSignupFormData.user.email &&
    pgSignupFormData.user.password &&
    pgSignupFormData.user.confirmpassword &&
    pgSignupFormData.tandc;

  const handleChange = (event) => {
    const { name, checked, value } = event.target;
    setPgSignupFormData((prevState) => {
      if (
        name === "username" ||
        name === "email" ||
        name === "password" ||
        name === "confirmpassword"
      ) {
        return {
          ...prevState,
          user: {
            ...prevState.user,
            [name]: value,
          },
        };
      } else if (name === "tandc") {
        return {
          ...prevState,
          [name]: checked,
        };
      } else {
        return {
          ...prevState,
          [name]: value,
        };
      }
    });
    setFormErrors((prevState) => {
      const errors = { ...prevState };
      delete errors[name];
      return errors;
    });
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(pgSignupFormData));
    setSubmitErrors(""); // clear any existing error messages
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      setIsLoading(true);
      const data = {
        user: {
          email: pgSignupFormData.user.email,
          password: pgSignupFormData.user.password,
          username: pgSignupFormData.user.username,
          confirm_password: pgSignupFormData.user.confirmpassword,
          first_name: pgSignupFormData.first_name,
          last_name: pgSignupFormData.last_name,
        },
        postcode: pgSignupFormData.postcode,
      };

      axios
        .post(process.env.REACT_APP_BACKEND_API_URL + "/register/parent/", data)
        .then((response) => {
          setSuccessMessage(
            "An email has been sent to the email you signed up with. Please click the link to activate your account. Don't forget to check your junk folder if you don't see it in your inbox"
          );
          setPgSignupFormData(initialValues);
          setSubmitErrors(""); // clear any submit error messages
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.user["username"]) {
              setSubmitErrors(error.response.data.user["username"][0]);
            } else if (error.response.data.user) {
              setSubmitErrors(error.response.data.user);
            }
          } else if (error.response == undefined) {
            setSubmitErrors("Unable to submit, please try again later.");
          }
        })
        .finally(() => {
          setIsSubmit(false);
          setIsLoading(false);
        });
    } else {
      setIsSubmit(false);
    }
  }, [formErrors, isSubmit, pgSignupFormData, setIsLoading]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const regex_p = /^[A-Z]{1,2}[0-9R][0-9A-Z]? ?[0-9][ABD-HJLNP-UW-Z]{2}$/i;
    const commonPasswords = [
      "123456",
      "password",
      "123456789",
      "12345678",
      "12345",
      "1234567",
      "1234567890",
      "admin",
      "qwerty",
      "111111",
      "123123",
      "abc123",
      "qwertyuiop",
      "123456a",
      "password1",
    ];

    if (!values.first_name) {
      errors.first_name = "First Name Required";
    } else if (values.first_name.length > 20) {
      errors.first_name = "First Name cannot exceed more than 20 characters";
    }

    if (!values.last_name) {
      errors.last_name = "Last Name is required";
    } else if (values.last_name.length > 20) {
      errors.last_name = "Last Name cannot exceed more than 20 characters";
    }

    if (!values.user.username) {
      errors.username = "Username is required";
    } else if (values.user.username.length > 20) {
      errors.username = "Username cannot exceed more than 20 characters";
    }

    if (!values.user.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.user.email)) {
      errors.email = "This is not a valid email format!";
    }

    if (!values.user.password) {
      errors.password = "Password is required";
    } else if (values.user.password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
    } else if (values.user.password.length > 30) {
      errors.password = "Password cannot exceed more than 30 characters";
    } else if (
      !/[a-z]/.test(values.user.password) ||
      !/[A-Z]/.test(values.user.password) ||
      !/[0-9]/.test(values.user.password) ||
      !/[^a-zA-Z0-9]/.test(values.user.password)
    ) {
      errors.password =
        "Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character";
    } else if (commonPasswords.includes(values.user.password)) {
      errors.password =
        "This is a commonly used password. Please choose a different one.";
    } else if (
      values.user.password.toLowerCase().includes(values.first_name.toLowerCase()) ||
      values.user.password.toLowerCase().includes(values.last_name.toLowerCase())
    ) {
      errors.password =
        "Your password can’t be too similar to your other personal information";
    } else if (/^\d+$/.test(values.user.password)) {
      errors.password = "Your password can’t be entirely numeric";
    }

    if (!values.postcode) {
      errors.postcode = "Post Code is required";
    } else if (!regex_p.test(values.postcode)) {
      errors.postcode = "This is not a valid Postcode format!";
    }

    if (!values.user.confirmpassword) {
      errors.confirmpassword = "Confirm password is required";
    } else if (values.user.password !== values.user.confirmpassword) {
      errors.confirmpassword = "Password is not matching";
    }

    if (!values.tandc) {
      errors.tandc = "Please accept our terms and conditions to proceed";
    }
    return errors;
  };

  return (
    <>
      {successMessage && <SuccessMessage message={successMessage} />}
      <Container
        fluid
        style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
        className="bg-[#F0F8FF]"
      >
        <Row>
          {/* On small screens the sign-up form appears first (order-1) and on md+ screens the platform section is on the left (order-md-1) */}
          <Col xs={12} sm={12} md={12} lg={12} xl={6}  className="order-2 order-xs-2  order-sm-2 order-md-2 order-lg-2  order-xl-1">
            {/* How Our Platform Works Section from Landing Page */}
            <div className="bg-[#F0F8FF] py-12">
              <div className="container mx-auto px-4">
                <div className="text-center mb-16">
                  <h2 className="text-4xl font-bold text-gray-900 mb-6">
                    How Our Platform Works
                  </h2>
                  <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                  Give your child the <b>best chance of success</b> in the <b>11+ exams</b> with our <b>simple, step-by-step preparation platform</b>.
                  </p>
                </div>
                <div className="grid md:grid-cols-2 gap-12 max-w-5xl mx-auto">
                  {/* Step 1 - Sign Up */}
                  <div className="flex items-start gap-6 group">
                    <div className="flex flex-col items-center">
                      <div className="bg-blue-100 rounded-full p-4 flex-shrink-0 shadow-sm transition-all duration-200 group-hover:bg-blue-200">
                        <div className="w-14 h-14 flex items-center justify-center">
                          <svg
                            className="w-8 h-8 text-blue-600"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path d="M16 21v-2a4 4 0 00-4-4H6a4 4 0 00-4 4v2" />
                            <circle cx="9" cy="7" r="4" />
                            <path d="M19 8v6M22 11h-6" />
                          </svg>
                        </div>
                      </div>
                      <span className="bg-blue-100 text-blue-700 text-sm font-medium px-3 py-1 rounded-full mt-2">
                        Step 1
                      </span>
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold text-gray-900">
                        Create Your Account
                      </h3>
                      <p className="text-gray-600 leading-relaxed">
                      Sign up for a <b>parent or guardian account</b> to get started. 
                      </p>
                    </div>
                  </div>
                  {/* Step 2 - Assessment */}
                  <div className="flex items-start gap-6 group">
                    <div className="flex flex-col items-center">
                      <div className="bg-blue-100 rounded-full p-4 flex-shrink-0 shadow-sm transition-all duration-200 group-hover:bg-blue-200">
                        <div className="w-14 h-14 flex items-center justify-center">
                          <svg
                            className="w-8 h-8 text-blue-600"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2" />
                            <rect x="9" y="3" width="6" height="4" rx="2" />
                            <path d="M9 14l2 2 4-4" />
                          </svg>
                        </div>
                      </div>
                      <span className="bg-blue-100 text-blue-700 text-sm font-medium px-3 py-1 rounded-full mt-2">
                        Step 2
                      </span>
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold text-gray-900">
                        Create Child Account
                      </h3>
                      <p className="text-gray-600 leading-relaxed">
                      Login and create a <b>child profile</b> with necessary details, including date of birth, to provide age-appropriate practice materials.
                      </p>
                    </div>
                  </div>
                  {/* Step 3 - Practice */}
                  <div className="flex items-start gap-6 group">
                    <div className="flex flex-col items-center">
                      <div className="bg-blue-100 rounded-full p-4 flex-shrink-0 shadow-sm transition-all duration-200 group-hover:bg-blue-200">
                        <div className="w-14 h-14 flex items-center justify-center">
                          <svg
                            className="w-8 h-8 text-blue-600"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path d="M2 3h6a4 4 0 014 4v14a3 3 0 00-3-3H2z" />
                            <path d="M22 3h-6a4 4 0 00-4 4v14a3 3 0 013-3h7z" />
                          </svg>
                        </div>
                      </div>
                      <span className="bg-blue-100 text-blue-700 text-sm font-medium px-3 py-1 rounded-full mt-2">
                        Step 3
                      </span>
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold text-gray-900">
                        Practice, Practice &amp; Practice 
                      </h3>
                      <p className="text-gray-600 leading-relaxed">
                      Access an <b>extensive library of expertly crafted practice tests</b> covering <b>Maths, English, and Verbal Reasoning.</b> Non-Verbal Reasoning is coming soon.
                      </p>
                    </div>
                  </div>
                  {/* Step 4 - Track Progress */}
                  <div className="flex items-start gap-6 group">
                    <div className="flex flex-col items-center">
                      <div className="bg-blue-100 rounded-full p-4 flex-shrink-0 shadow-sm transition-all duration-200 group-hover:bg-blue-200">
                        <div className="w-14 h-14 flex items-center justify-center">
                          <svg
                            className="w-8 h-8 text-blue-600"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path d="M3 3v18h18" />
                            <path d="M18 17l-3-3-4 4-3-3" />
                            <path d="M3 12h4l3-3 4 4 3-3h4" />
                          </svg>
                        </div>
                      </div>
                      <span className="bg-blue-100 text-blue-700 text-sm font-medium px-3 py-1 rounded-full mt-2">
                        Step 4
                      </span>
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold text-gray-900">
                        Track Progress &amp; Improve
                      </h3>
                      <p className="text-gray-600 leading-relaxed">
                      Stay informed about your child's <b>performance and progress</b> through <b>detailed analytics and personalised reports.</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6}  className="order-1 order-xs-2  order-sm-2 order-md-1 order-lg-1 order-xl-2 d-flex align-items-center justify-content-center bg-[#F0F8FF] ">
            <Card className="mt-4 " style={{ maxWidth: "600px", margin: "0 auto" }}>
              <Card.Body>
                <h3 className="fw-bold mb-4 pb-2 pb-md-0 mb-md-5">Sign Up</h3>
                {submitErrors && <Alert key="danger" variant="danger">{submitErrors}</Alert>}
                <Row>
                  <Col md="6">
                    <Form.Group className="mb-2" controlId="form1">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="first_name"
                        value={pgSignupFormData.first_name}
                        onChange={handleChange}
                        isInvalid={formErrors.first_name}
                        onKeyDown={handleEnterKeyPress}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formErrors.first_name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group className="mb-2" controlId="form2">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        size="md"
                        type="text"
                        name="last_name"
                        value={pgSignupFormData.last_name}
                        onChange={handleChange}
                        isInvalid={formErrors.last_name}
                        onKeyDown={handleEnterKeyPress}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formErrors.last_name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Form.Group className="mb-2" controlId="form3">
                      <Form.Label style={{ marginRight: "4px" }}>Username</Form.Label>
                      <OverlayTrigger
                        placement="top"
                        overlay={usernameTooltip}
                        show={usernameTooltipOpen}
                      >
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          onMouseEnter={() => setUsernameTooltipOpen(true)}
                          onMouseLeave={() => setUsernameTooltipOpen(false)}
                        />
                      </OverlayTrigger>
                      <Form.Control
                        size="md"
                        type="text"
                        name="username"
                        value={pgSignupFormData.user.username}
                        onChange={handleChange}
                        isInvalid={formErrors.username}
                        onKeyDown={handleEnterKeyPress}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formErrors.username}
                      </Form.Control.Feedback>
                      {submitErrors.username}
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group className="mb-2" controlId="form4">
                      <Form.Label style={{ marginRight: "4px" }}>Postcode</Form.Label>
                      <OverlayTrigger
                        placement="top"
                        overlay={postcodeTooltip}
                        show={postcodeTooltipOpen}
                      >
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          onMouseEnter={() => setPostcodeTooltipOpen(true)}
                          onMouseLeave={() => setPostcodeTooltipOpen(false)}
                        />
                      </OverlayTrigger>
                      <Form.Control
                        size="md"
                        type="zip"
                        name="postcode"
                        value={pgSignupFormData.postcode}
                        onChange={handleChange}
                        isInvalid={formErrors.postcode}
                        onKeyDown={handleEnterKeyPress}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formErrors.postcode}
                      </Form.Control.Feedback>
                      {submitErrors.postcode}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-2" controlId="form4">
                      <Form.Label style={{ marginRight: "4px" }}>Email</Form.Label>
                      <OverlayTrigger
                        placement="top"
                        overlay={emailTooltip}
                        show={emailTooltipOpen}
                        style={{ textAlign: "left" }}
                      >
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          onMouseEnter={() => setEmailTooltipOpen(true)}
                          onMouseLeave={() => setEmailTooltipOpen(false)}
                        />
                      </OverlayTrigger>
                      <Form.Control
                        size="md"
                        type="email"
                        name="email"
                        value={pgSignupFormData.user.email}
                        onChange={handleChange}
                        isInvalid={formErrors.email}
                        onKeyDown={handleEnterKeyPress}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formErrors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Form.Group className="mb-2" controlId="form4">
                      <Form.Label>Password</Form.Label>
                      <div className="input-group">
                        <Form.Control
                          size="md"
                          type={showPassword ? "text" : "password"}
                          name="password"
                          value={pgSignupFormData.user.password}
                          onChange={handleChange}
                          isInvalid={formErrors.password}
                          onKeyDown={handleEnterKeyPress}
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary"
                          onClick={handleTogglePassword}
                        >
                          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                          <span className="visually-hidden">
                            {showPassword ? "Hide" : "Show"} password
                          </span>
                        </button>
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {formErrors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group className="mb-2" controlId="form4">
                      <Form.Label>Confirm Password</Form.Label>
                      <div className="input-group">
                        <Form.Control
                          size="md"
                          type={showConfirmPassword ? "text" : "password"}
                          name="confirmpassword"
                          value={pgSignupFormData.user.confirmpassword}
                          onChange={handleChange}
                          isInvalid={formErrors.confirmpassword}
                          onKeyDown={handleEnterKeyPress}
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary"
                          onClick={handleToggleConfirmPassword}
                        >
                          <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                          <span className="visually-hidden">
                            {showConfirmPassword ? "Hide" : "Show"} password
                          </span>
                        </button>
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {formErrors.confirmpassword}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group controlId="formBasicCheckbox" className="mb-2">
                  <Form.Check
                    type="checkbox"
                    name="tandc"
                    checked={pgSignupFormData.tandc}
                    onChange={handleChange}
                    onKeyDown={handleEnterKeyPress}
                    className={formErrors.tandc ? "error-checkbox" : ""}
                    label={
                      <>
                        By registering, you accept our{" "}
                        <a href="/termsandconditions" target="_blank" rel="noopener noreferrer">
                          terms and conditions
                        </a> & <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                          privacy policy
                        </a>.
                      </>
                    }
                  />
                  {formErrors.tandc && (
                    <div className="text-danger">{formErrors.tandc}</div>
                  )}
                </Form.Group>

                <Row>
                  <Button onClick={handleSubmit} disabled={!isFormFilled} className="mb-1" size="lg">
                    Create Parent Account
                  </Button>
                </Row>
                <hr className="my-4" />
                <p>
                  Already have an account?{" "}
                  <Link to="/">
                    Signin here
                  </Link>
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ParentGuardianSignUp;
