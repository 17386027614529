import React from 'react';
import { Helmet } from 'react-helmet';

const AboutExams = () => {
    return (
        <>
            <Helmet>
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>About 11 Plus | Practice Papers</title>
                <meta name="description" content="A comprehensive guide for parents on the 11 Plus exam, covering key topics, preparation strategies, and school selection." />
                <meta name="keywords" content="11 plus exam, grammar schools, UK education, 11+ preparation, selective schools, entrance exam" />
                <meta name="author" content="Practice Papers" />
                <style>{`
                * { margin: 0; padding: 0; box-sizing: border-box; }
                .about-11-plus-container {
                                            max - width: 900px;
                                        margin: 80px auto 40px;
                                        padding: 50px;
                                        background: white;
                                        box-shadow: 0 4px 10px rgba(0,0,0,0.1);
                                        border-radius: 8px;
  }
                                        h1 {color: #007BFF; text-align: center; }
                                        .updated-date {text - align: center; color: gray; font-size: 0.9em; margin-bottom: 20px; }
                                        h2 {margin - top: 20px; color: #0056b3; font-size: 18px}
                                        ul {margin: 10px 0; padding-left: 20px; }
                                        ul li {margin - bottom: 5px; }
                                        ol {margin: 10px 0; padding-left: 20px; }

                                        .decorative-graduation {
                                            position: absolute;
                                        top: 10%;
                                        right: 10%;
                                        z-index: 2;
                                        opacity: 0.9;
                                        width: 48px;
                                        height: 48px;
  }
                                        .practice-highlight {
                                            background: #f8f9fa; /* Light grey background for subtle emphasis */
                                        border-left: 5px solid #ffa600; /* Blue left border for a visual cue */
                                        padding: 15px;
                                        font-size: 0.9rem;
                                        margin-top: 20px;
                                        border-radius: 5px;
}
                                        .practice-highlight p {
                                            margin: 0;
                                        color: #333;
}
                                        .green-tick-list {
                                            list - style: none; /* Remove default bullets */
                                        padding-left: 0;
}

                                        .green-tick-list li {
                                            position: relative;
                                        padding-left: 30px; /* Space for tick */
                                        margin-bottom: 10px;
}

                                        .green-tick-list li::before {
                                            content: "✔"; /* Checkmark */
                                        color: green;
                                        font-size: 1.2em;
                                        position: absolute;
                                        left: 0;
                                        top: 2px;
}



                                        @media (max-width: 768px) {
        .features - container {flex - direction: column; align-items: center; }
                                        .feature {width: 80%; }
                                        .carousel-slide .slide-content h2 {font - size: 2.2em; }
                                        .carousel-slide .slide-content p {font - size: 1em; }
                                    }
                                        `}

                </style>
            </Helmet>
            <main class="about-11-plus-container">
                <h1>About the 11 Plus Exam </h1>
                <h1>A Comprehensive Guide for Parents</h1>
                <p class="updated-date">Last Updated: February 2025</p>

                <p>The 11 Plus exam!!! Just the mention of it can evoke a mix of anticipation, anxiety, and a whole lot of questions for parents. If you're feeling a bit overwhelmed by the prospect of the 11+, you're not alone. This comprehensive guide aims to demystify the process, providing you with the information you need to support your child through this important stage.</p>

                <section>
                    <h2>What is the Eleven Plus?</h2>
                    <p>The Eleven Plus is an entrance examination used by various schools — most notably selective grammar schools and other academically selective secondary schools — to assess a child’s academic potential at the transition from primary to secondary education. The exam aims to evaluate skills in areas such as English, Maths, and reasoning, both verbal & non-verbal, helping schools identify students who may thrive in a more academically challenging environment. Think of it as a gateway to a specific type of secondary education.
                        <p>Key takeaway: It isn’t a measure of overall intelligence but rather an assessment of skills and aptitudes that certain schools value.</p>
                    </p>
                </section>

                <section>
                    <h2>Which schools is the 11+ for?</h2>
                    <p>TThe exam is primarily used for entry into selective schools, including:</p>
                    <ul>
                        <li><b>Grammar Schools: </b>State-funded schools that select pupils based on academic performance.</li>
                        <li><b>Some Independent or Selective Academies: </b>Schools that may use the exam as part of their admission process.</li>
                        <li><b>Specialist Institutions: </b>In a few regions, other types of schools use similar testing methods to determine entry.</li>
                    </ul>
                    <p>Not every school in the UK uses the 11+; many comprehensive schools do not require an entrance exam.</p>
                </section>

                <section>
                    <h2>Where and when does the 11+ take place?</h2>
                    <h4>Where</h4>
                    <p>TThe exam is usually administered by local authorities or regional examination boards. The testing centres are typically set up within local schools or dedicated exam centres.</p>
                    <h4>When:</h4>
                    <p>The 11+ exam is typically taken in the autumn term of Year 6 (the final year of primary school) when children are around 10-11 years old. The exact date varies by region, so it’s essential to check with your local authority or school for specific details.</p>
                </section>

                <section>
                    <h2>Do all children have to take the 11+?</h2>
                    <p>No, the 11+ is not compulsory for all children. It's only for children who wish to apply for a place at a grammar school. If your child is not aiming for a grammar school, they do not need to take the 11+. The majority of children in the UK attend comprehensive schools, which do not have selective entry requirements.</p>
                    <ul>
                        <li><b>Selective Areas: </b>In regions where grammar schools or other selective institutions are popular, many parents opt to have their children take the exam</li>
                        <li><b>Non-Selective Areas: </b>In regions with predominantly comprehensive schools, the 11+ may not be offered or required.</li>
                    </ul>
                    <p>It’s important to consider your child’s needs and the available educational options in your area when deciding whether to pursue the 11+ route.</p>
                </section>

                <section>
                    <h2>What is covered in the 11+ exam?</h2>
                    <p>The 11+ exam typically assesses skills in the following areas:</p>
                    <ul>
                        <li><b>English: </b>This section usually covers comprehension, creative writing, grammar, and vocabulary. Children may be asked to analyse passages of text, write stories, and demonstrate their understanding of language.</li>
                        <li><b>Mathematics: </b>This section tests mathematical skills and problem-solving abilities. The content generally aligns with the Key Stage 2 curriculum, but the questions can be more challenging.</li>
                        <li><b>Verbal Reasoning: </b>This assesses a child's ability to think logically and solve problems using language. Questions may involve word puzzles, analogies, and identifying patterns in words.</li>
                        <li><b>Non-Verbal Reasoning: </b>This section evaluates problem-solving skills using visual materials such as shapes, patterns, and diagrams. It tests a child's ability to think logically without relying on language.</li>
                    </ul>
                    <p>Some regions or schools might include additional tests or components, so always check the specific requirements of your local authority or the school in question.</p>
                </section>

                <section>
                    <h2>How does the 11+ exam differ across the country?</h2>
                    <p>The 11+ exam can vary significantly across different regions and even between individual schools. The content, format, and style of the exam can differ. Some areas use papers provided by GL Assessment, while others use papers from CEM (Centre for Evaluation and Monitoring). CEM exams are often designed to be less predictable and focus more on assessing broader skills, while GL Assessment exams tend to be more traditional in format. It's crucial to research the specific 11+ requirements for the schools your child is interested in. Some other variations include:</p>
                    <ul>
                        <li><b>Exam Format: </b>Some areas use paper-based tests while others have moved to computer-based assessments.</li>
                        <li><b>Content Emphasis: </b>Different regions may weigh subjects differently (for example, some might focus more on verbal reasoning while others emphasise maths).</li>
                        <li><b>Scoring and Banding: </b>The methods for scoring and setting pass marks can differ significantly. Some authorities rank students and set cut-off points, while others use banding systems.</li>
                    </ul>
                    <p>This regional variation means that it’s crucial to understand the specifics of the exam as administered in your local area.</p>
                </section>
                <section>
                    <h2>How does the 11+ exam work?</h2>
                    <p>The 11+ process generally involves:</p>
                    <ol>
                        <li><b>Registration: </b>Parents must register their child for the 11+ exam with the relevant school or local authority. There are usually deadlines for registration, so it's essential to be aware of these.</li>
                        <li><b>Examination: </b>Most often, children sit the exam on one day, tackling several subjects in succession.</li>
                        <li><b>Timed Papers: </b>Each section is timed, testing not just knowledge but also speed and accuracy.</li>
                        <li><b>Ranking or Banding: </b>Instead of a simple “pass” or “fail,” many local authorities rank pupils according to their scores, with schools setting admission cut-off points.</li>
                        <li><b>Additional Stages: </b>Some schools might require an interview or further assessments after the exam.</li>
                        <li><b>School Application: </b>Based on the 11+ results, parents then apply to their chosen grammar schools. The 11+ score is usually a significant factor in the admissions process, but schools may also consider other criteria, such as catchment area or siblings already attending the school.</li>
                    </ol>
                    <p>The exact process depends on your local education authority and the schools you’re targeting.</p>
                </section>

                <section>
                    <h2>How to prepare my child for the 11+ Exam?</h2>
                    <p>Preparing for the 11+ requires a consistent and strategic approach. Here are some tips:</p>
                    <ul>
                        <li><b>Start Early: </b>The 11+ exam is extremely competitive. Begin preparation well in advance, ideally in Year 3 or Year 4. This allows ample time to cover all the necessary material and build confidence</li>
                        <li><b>Practice Regularly: </b>Regular practice is essential. Use past papers, practice questions, and online resources to familiarise your child with the exam format and question types</li>
                        <li><b>Identify Weaknesses: </b>Focus on areas where your child struggles. Targeted practice in these areas can significantly improve their performance.</li>
                        <li><b>Build Confidence: </b>Encourage your child and build their confidence. A positive attitude can make a big difference.</li>
                        <li><b>Use a Variety of Resources: </b>Utilise a range of resources, such as practice papers, revision books, and online platforms. Consider whether a tutor might be beneficial for your child. Be aware that tuition often costs in thousands of pounds.</li>
                        <li><b>Make it Manageable: </b>Break down the preparation into smaller, manageable chunks. Avoid overwhelming your child with too much work at once.</li>
                        <li><b>Focus on Understanding: </b>Don't just focus on memorising facts. Ensure your child understands the underlying concepts and can apply them to different problem-solving scenarios.</li>
                    </ul>
                    <p>Remember: The goal is to build your child’s confidence as well as competence.</p>
                    <div class="practice-highlight">
                        <p><strong>Practice Makes Progress:</strong> Consistent daily practice is far more effective than cramming. Encouraging your child to practice for <strong>30 minutes a day</strong> builds strong retention and confidence, compared to long, intense study sessions once a week. Small, regular efforts lead to <strong>steady progress and long-term success</strong> in the 11+ exam.</p>
                    </div>

                </section>

                <section>
                    <h2>When shall I start preparing my child for the 11+?</h2>
                    <p>Considering the competitive nature of the exam, it is recommended to start either in second half of Year 3 or early Year 4. Starting sooner is benefical provided your child is ready for this. This gives children enough time to cover the curriculum, develop their skills, and become familiar with the exam format. However, every child is different, and some may benefit from starting earlier or later. Consider your child's individual learning style and pace when deciding when to begin.</p>
                    <p>If starting early, be aware that yoir child will not be ready for exam style or depth of questions. So, the preparation should focus on:</p>
                    <ul>
                        <li><b>Building a Strong Foundation: </b>Focus on fundamental literacy and numeracy skills.</li>
                        <li><b>Developing a Positive Attitude: </b>Introduce exam-style questions gradually, ensuring the child remains confident and motivated.</li>
                        <li><b>Avoiding Pressure: </b>It’s essential not to overload your child too early—maintain a balance between learning and free time.</li>
                    </ul>
                </section>

                <section>
                    <h2>How do children pass the 11 + Exam?</h2>
                    <p>The scoring system varies by school and region, with **standardised scores** used to ensure fairness. Generally, the raw scores are standardised to take into account the age of the children and the difficulty of the exam. Each school sets its own pass mark or qualifying score. This means there isn't a universal "pass" mark for the 11+. Your child's score will be compared to the scores of other applicants to the specific school they are applying to. The known guidelines are: </p>
                    <ul>
                        <li><b>Relative Performance: </b>Many local authorities use a ranking system, where pupils are placed in order based on their exam scores.</li>
                        <li><b>Cut-Off Points: </b>Schools set their own entry cut-offs, which can vary each year depending on overall performance.</li>
                        <li><b>Banding: </b>Some regions assign pupils to bands (e.g., top band, middle band, etc.), and admission is then based on which band your child falls into.</li>
                        <li><b>Multiple Components: </b>In some cases, if there are interviews or additional assessments, these may contribute to the final selection decision.</li>
                    </ul>
                    <p>Thus, “passing” is about performing well enough relative to other candidates rather than reaching an absolute score.</p>
                </section>

                <section>
                    <h2>What happens after the 11+ exam?</h2>
                    <p>After the exam, you will receive your child's results. If your child achieves the required score, you can then apply to the grammar school(s) of your choice. The 11+ score is usually a significant factor in the admissions process, but schools may also consider other factors, such as catchment area, siblings already attending the school, and performance in other assessments or interviews. If your child is not successful in gaining a place at a grammar school, they will usually attend a comprehensive school.</p>
                    <p>The 11+ can be a challenging process, but with careful planning, consistent preparation, and plenty of support, you can help your child navigate it successfully. Remember to stay informed, stay positive, and focus on supporting your child's learning journey.</p>
                    <p>It’s important to have a backup plan and to research other schooling options in your area.</p>

                </section>

                <section>
                    <h2>Common Misconceptions about 11+ Exam</h2>
                    <ul>
                        <li><b>It’s All about IQ: </b>The exam assesses specific academic skills and reasoning, not innate intelligence.</li>
                        <li><b>Early Specialisation: </b>Early preparation doesn’t mean overloading your child with pressure—it’s about gentle, gradual exposure.</li>
                        <li><b>Only for High Achievers: </b>While the exam is competitive, many resources are available to help all children improve.</li>
                    </ul>
                </section>

                <section>
                    <h2>Supporting your child’s wellbeing during 11+ preparation</h2>
                    <ul>
                        <li><b>Encourage a Balanced Lifestyle: </b>Ensure your child has time for extracurricular activities, socialising, and rest.</li>
                        <li><b>Keep Communication Open: </b>Talk to your child about their worries and celebrate small achievements.</li>
                        <li><b>Stress Management: </b>Introduce techniques like mindfulness or simple breathing exercises to help them manage exam anxiety.</li>
                    </ul>
                </section>
                <section>
                    <div class="practice-highlight">
                        <h2>Managing Failure: A Step Forward, Not the End</h2>
                        <p>Not securing a grammar school place can feel like a setback, but it’s important to remember that one exam does not define your child’s future. Success comes in many forms, and there are plenty of alternative paths to academic and personal growth.</p>
                        <ul class="green-tick-list">
                            <li><strong>Alternative Options</strong> – Many comprehensive schools and specialist colleges provide excellent education and tailored support.</li>
                            <li><strong>Reassessment and Transition</strong> – The skills developed during 11+ preparation are valuable beyond the exam. The structured learning, problem-solving abilities, and exam techniques acquired during this process give children a strong academic foundation, helping them stand ahead in Year 7 when they start secondary school. The experience builds confidence, resilience, time management, and analytical thinking, all of which are crucial for success in higher education and beyond.</li>
                            <li><strong>Future Opportunities</strong> – Education is a journey, and success is built through hard work, adaptability, and perseverance.</li>
                        </ul>

                    </div>

                </section>
            </main>
        </>
    )
}

export default AboutExams;