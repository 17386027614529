import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Helmet } from 'react-helmet';
const PracticeTestInstructions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [agreed, setAgreed] = useState(false);
  const [formInput, setFormInput] = useState({
    subject: "",
    noofquestion: ""
  });

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Token ${localStorage.getItem("authToken")}`
      }
    };

    axios.get(process.env.REACT_APP_BACKEND_API_URL + "/practice-test-check-active-session/", config)
      .then(response => {
        setMessage(response.data.unsubmitted)
      })
      .catch(error => {
        console.error("Error checking unsubmitted exams:", error);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormInput(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadingText('Please wait while we create a Practice Test for you...');
    setIsLoading(true);

    const data = {
      num_questions: formInput.noofquestion,
      subject: formInput.subject
    };

    const config = {
      headers: { 
        Authorization: `Token ${localStorage.getItem("authToken")}` 
      }
    };

    axios.post(process.env.REACT_APP_BACKEND_API_URL + "/practice-test/", data, config)
      .then((response) => {
        localStorage.setItem("responseData", JSON.stringify(response.data));
        localStorage.removeItem("data");
        setIsLoading(false);
        navigate("/practicetest");
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error creating practice test:", error);
      });
  };

  const handleProceedToExam = (e) => {
    e.preventDefault();
    const config = {
      headers: { 
        Authorization: `Token ${localStorage.getItem("authToken")}` 
      }
    };
    
    const data = {
      "unsubmitted": true
    };

    axios.post(process.env.REACT_APP_BACKEND_API_URL + "/practice-test/", data, config)
      .then((response) => {
        localStorage.setItem("responseData", JSON.stringify(response.data));
        localStorage.setItem("data", JSON.stringify(response.data.answered_questions));
        navigate("/practicetest");
      })
      .catch(error => {
        console.error("Error proceeding to exam:", error);
      });
  };

  if (message) {
    return (
      <div className="max-w-2xl mx-auto p-6">
        <div className="bg-white rounded-xl shadow-lg p-6">
          <h2 className="text-xl font-bold text-center mb-4">Unsubmitted Exam Found</h2>
          <p className="text-gray-700 mb-4">You have an unsubmitted exam. Please proceed to the exam, submit and view results.</p>
          <button 
            onClick={handleProceedToExam}
            className="w-full py-3 bg-violet-600 text-white rounded-lg hover:bg-violet-700 font-medium"
          >
            Proceed to Exam
          </button>
        </div>
      </div>
    );
  }

  const instructions = [
    { emoji: "📝", text: "Keep a pencil and paper ready for calculations", title: "Prepare your tools" },
    { emoji: "⏱️", text: "The on-screen timer starts immediately. Pace yourself accordingly", title: "Time management" },
    { emoji: "🔄", text: "The test will submit when time runs out", title: "Automatic submission" },
    { emoji: "↪️", text: "You can skip questions and return to them if time allows", title: "Skipping questions" },
    { emoji: "🚩", text: "Flag tricky questions to revisit before submitting", title: "Mark for review" },
    { emoji: "🎯", text: "Read questions carefully and attempt them to the best of your ability", title: "Stay focused" }
  ];

  const showInstructions = formInput.subject && formInput.noofquestion;

  return (
    <> <Helmet>
      <style>{`

    .container {
        max-width: 700px;
        margin: 50px auto;
        background: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }
    .warning {
        background-color: #fff3cd;
        color: #856404;
        border-left: 5px solid #ffcc00;
        padding: 15px;
        border-radius: 5px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
    }
    .warning-icon {
        font-size: 20px;
        margin-right: 10px;
    }
    .subtle-box {
        background-color: #f1f1f1;
        padding: 10px;
        border-radius: 5px;
        text-align: center;
        border-left: 4px solid #007bff;
    }
        `}
</style>
</Helmet>
    <div className="max-w-5xl mx-auto p-6">
      <div className="bg-white rounded-xl shadow-lg p-6">
      <h2 className="font-bold text-lg mb-4 text-gray-900 text-center">New Practice Test</h2>
        {/* Warning Box */}
        <div className="flex items-start gap-3 bg-amber-50 border-l-4 border-amber-500 p-4 rounded-lg mb-6">
          <span className="text-2xl">⚠️</span>
          <div>
            <p className="font-medium text-amber-800">Avoid Taking Test on Mobile Phones</p>
            <p className="text-amber-700">This test contains detailed questions, tables, and images. Use a desktop, laptop or tablet for best experience.</p>
          </div>
        </div>

        {/* Subject Selection */}
        <div className="space-y-4 mb-6">
          <h4 className="text-xl font-semibold text-center text-gray-900 mb-3">Select Subject and Number of Questions</h4>
          <div className="flex justify-center gap-4">
            <select 
              name="subject"
              value={formInput.subject}
              onChange={handleInputChange}
              className="px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-violet-500"
            >
              <option value="">Select Subject</option>
              <option value="Maths">Maths</option>
              <option value="English">English</option>
              <option value="VR">VR</option>
            </select>

            <select 
              name="noofquestion"
              value={formInput.noofquestion}
              onChange={handleInputChange}
              className="px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-violet-500"
            >
              <option value="">Select Questions</option>
              <option value="25">25</option>
            </select>
          </div>
        </div>

        {showInstructions && (
          <>
            {/* Instructions */}
            <div className="bg-gray-50 rounded-xl p-6  mb-6">
              <h3 className="font-bold text-lg mb-4 text-gray-900 text-center">Instructions</h3>
              <ul className="space-y-4 ">
                {instructions.map((instruction, index) => (
                  <li key={index} className="flex items-start gap-3">
                    <span className="text-2xl w-8 h-8 flex items-center justify-center bg-white rounded-full shadow-sm">
                      {instruction.emoji}
                    </span>
                    <div>
                      <span className="font-semibold text-gray-900">{instruction.title}:</span>
                      <span className="text-gray-700 ml-1">{instruction.text}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            {/*Final Step Message */}
        <div class="mt-4 mb-4 subtle-box">
            <p class="text-gray-800 font-semibold">
                ✅ Click "<strong>Get Practice Test</strong>" when you are ready.<br/>
                📌 You must check the box below to enable the button.
            </p>
        </div>

            {/* Agreement Checkbox */}
            <div className="flex items-center gap-2 mb-4">
              <input 
                type="checkbox" 
                id="agree" 
                checked={agreed}
                onChange={(e) => setAgreed(e.target.checked)}
                className="w-4 h-4 rounded border-gray-300 text-violet-600 focus:ring-violet-500"
              />
              <label htmlFor="agree" className="text-gray-700">
                I have read and understood the instructions
              </label>
            </div>

            {/* Start Button */}
            <button 
              onClick={handleSubmit}
              disabled={!agreed}
              className={`w-full py-2 rounded-lg transition-colors font-medium
                ${agreed 
                  ? 'bg-blue-600 hover:bg-blue-700 text-white cursor-pointer rounded' 
                  : 'bg-gray-200 text-gray-500 cursor-not-allowed rounded'}`}
            >
              Start Practice Test
            </button>
          </>
        )}
      </div>

      {/* Loading Overlay */}
      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-violet-600 mx-auto mb-4"></div>
            <p className="text-gray-700">{loadingText}</p>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default PracticeTestInstructions;